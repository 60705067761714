
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../components/Page"
import { FormControl, FormControlAction, FormControlInput, PageTitle } from "../../globalStyles";
import { Button } from "../../components/Button"
import { LoginWrapper, LoginWrapper_Global, LoginWrapper_Global_DOS } from "./styles";
import { ButtonIcon } from "../../components/ButtonIcon";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { useForm } from "react-hook-form";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { useAuth } from "../../hooks/useAuth";
import { PropertyProfile } from "../Home/components/PropertyProfile";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { setToken } from "../../utils/TokenLS";


export const Login = () => {
    const [visiblePassword, setVisiblePassword] = useState(false)

    const tooglePasswordVisible = () => {
        setVisiblePassword(!visiblePassword)
    }

    const { setAuth } = useAuth();

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isValid
        }
    } = useForm({ mode: 'onChange' });

    const onSubmitLogin = (data) => {
        loginRequest(data);
    }

    const loginRequest = async (data) => {

        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.POST,
                    endpoint: '/users/login',
                    body: data
                }
            );
            setAuth(response)
            //console.log({res: response}) 
            //localStorage.setItem('token_Sisgc', response.data.token);           
            setToken(response.data.token)
            showAlert(
                'Bienvenido',
                "Credenciales Correctas",
                SW_ICON.SUCCESS,
                () => { navigate('/') }
            );
        } catch (error) {
            showAlert('Error', "Credenciales Incorrectas", SW_ICON.ERROR);
        }

    }
    //<a href="/Signup" >¿No tienes una cuenta? Regístrate</a>    
    return (
        <Page>
            <LoginWrapper_Global>
                <LoginWrapper_Global_DOS>
                    <LogoImageWrapper>
                        <PageTitle>
                            GRUPO CENTRAL
                        </PageTitle>
                        <img src={"Logo.jpg"} alt="imgLogo" />
                    </LogoImageWrapper>

                    <br />
                    <form onSubmit={handleSubmit(onSubmitLogin)}>
                        <FormControl>
                            <FormControlInput>
                                <label >Correo Electrónico</label>
                                <input type="email"
                                    {...register("email", { required: true, pattern: /\S+@\S+\.\S+/ })}
                                />
                                {errors.email?.type === 'required' && <span>Este Campo es requerido</span>}
                                {errors.email?.type === 'pattern' && <span>Ingrese un email valido</span>}
                            </FormControlInput>
                        </FormControl>
                        <FormControl>
                            <FormControlInput>
                                <label  >Password</label>
                                <input type={visiblePassword ? "text" : "password"}
                                    {...register("password", { required: true })}
                                />
                                {errors.password?.type === 'required' && <span>Este password es requerido</span>}
                            </FormControlInput>
                            <FormControlAction>
                                <ButtonIcon icon={visiblePassword ? IoEye : IoEyeOff} onPress={tooglePasswordVisible} />
                            </FormControlAction>
                        </FormControl>
                        <br />
                        <Button disabled={!isValid} type="submit" onPress={() => { }} label="Iniciar Sesión" />
                        <br />
                        <LoginWrapper>

                            <a href="/ForgetPassword" >¿Olvido Su Password?</a>
                        </LoginWrapper>
                    </form>

                </LoginWrapper_Global_DOS>
            </LoginWrapper_Global>
        </Page>
    )
};