import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/useAuth"
import { useRdt } from "../../../../hooks/useRdt";
import { getToken, removeToken } from "../../../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import swal from 'sweetalert';
import Alert from "../../../../components/Alert/Alert"


import { ButtonIcon } from "../../../../components/ButtonIcon"
import { IoCloseCircleOutline } from "react-icons/io5";
import { CerrarModal, FormControlModal, PropertyInfoWrapper, PropertyItemLab, PropertyTable, PropertyTableCell, PropertyTableCellRigth, PropertyTableHeader, PropertyTableHeaderRow, PropertyTableRow } from "./styles"
import { PropertyGroup, PropertyItem, PropertyItemButton, PropertyItemRdt, PropertysubGroup, PropertyWorkerslistGroup } from "./styles";



export const ModalRdtsempaque = ({ setModalCinco, animarModalcinco, setAnimarModalcinco, sucursalSeleted, fechaini, groups }) => {

    //console.log({ sucursalModal: sucursalSeleted })
    //console.log({ fechaModal: fechaini })
    //console.log({ groups: groups })

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov } = useRdt();

    const [rdts, setRdts] = useState([]);
    const [tets, setTest] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getDataRdts = async () => {
            try {
                const token = getToken();
                if (!token) return
                setTimeout(() => {
                    getRdts();
                }, 200);

            } catch (error) {
                console.log(error)
            }
        }
        getDataRdts();
    }, [])
    useEffect(() => {
        if (groups && rdts.length > 0) {
            //getEmployeesRdts();
            getUpdatedGroups();
        }
    }, [groups, rdts]);
    //getRdts
    const getRdts = async () => {

        if (!fechaini) {
            setRdts([])
            return
            //console.log('Fecha vacia')
        }
        if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
            setRdts([])
            return
            //console.log({nohacerConsulta: sucursalSeleted})        
        } else {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/analyses',
                        params: makePropertiesFilters()
                    }
                );
                const { data } = response;
                data ? setRdts(data) : console.log('');
                //console.log({ dataRdtModal: data })

            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
            //console.log({hacerConsulta: sucursalSeleted})        
        }
    }
    const makePropertiesFilters = () => {
        const filters = {};
        filters['Fecha_INI'] = fechaini;
        filters['Fecha_FIN'] = fechaini;
        filters['IbmLaboro'] = sucursalSeleted;
        //console.log(fechaini);
        return filters;
    }
    //Close session if token expired
    const hendleCloseSeccion = async () => {
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente',
            "Su token puede estar vencido",
            SW_ICON.INFO,
            () => { navigate('/Login') }
        );
    }
    //Boton de cerrar Modal
    const ocultarModal = () => {
        //setAnimarModal(false)
        setModalCinco(false)
    }
    //Funcion para sacar los Rdts de los empleados asignados al reparto
    const getUpdatedGroups = () => {
        // Iterar sobre cada grupo en el objeto `groups`
        Object.keys(groups).forEach(groupKey => {
            // Si el grupo tiene trabajadores
            if (groups[groupKey].length > 0) {
                groups[groupKey] = groups[groupKey].map(worker => {
                    // Buscar todas las labores reportadas del trabajador actual
                    const matchingRdts = rdts.filter(rdt => rdt.IntCodigo_EMPL === worker.IntCodigo);


                    // Formar el array de labores reportadas incluyendo `strUdp`
                    const laboresReportadas = matchingRdts.map(rdt => ({
                        IntCodigo: rdt.IntCodigo_CONC,
                        strConcepto: rdt.strConcepto,
                        intHora: rdt.intHora_RDT,
                        intTotal: rdt.intTotal_RDT,
                        observacion: rdt.strObservacion_RDT,
                        usuarioReg: rdt.email_USUA,
                        Udps: `${rdt.strNombre_UDP || ''}: ${rdt.intCantidad_UDP || ''}, ${rdt.strNombre_UDPdos || ''}: ${rdt.intCantidad_UDPdos || ''}, ${rdt.strNombre_UDPtres || ''}: ${rdt.intCantidad_UDPtres || ''}, ${rdt.strNombre_UDPcuatro || ''}: ${rdt.intCantidad_UDPcuatro || ''}`,
                        strUdp: rdt.strUdp.filter(udp => udp.IntCodigo_UDP && udp.IntCodigo_UDP !== "")
                    }));
                    // Añadir el array de labores al trabajador
                    return {
                        ...worker,
                        Labreportadas: laboresReportadas.length > 0 ? laboresReportadas : null
                    };
                });
            }
        });
        //console.log({ groupsUpdate: groups })
        return groups;
    };


    return (
        <FormControlModal>
            <CerrarModal>
                <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
            </CerrarModal>
            {/*CAMPO*/}
            <PropertyWorkerslistGroup>
                <h2>EMPACADORA: {groups.group13.length + groups.group14.length + groups.group15.length + groups.group16.length + groups.group17.length +
                    groups.group18.length + groups.group19.length + groups.group20.length + groups.group30.length + groups.group31.length +
                    groups.group32.length + (groups.group48 ? groups.group48.length : 0)} <span>{fechaini}</span>
                </h2>
                <PropertysubGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Barcadilla: <span>{groups.group13.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group13.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}
                        <br />
                    </PropertyGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Guantelete: <span>{groups.group14.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group14.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />
                    </PropertyGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Cochinilla: <span>{groups.group15.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group15.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Bolsa Nacional: <span>{groups.group48 ? groups.group48.length : 0}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group48.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Cartonera: <span>{groups.group19.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group47 && groups.group19.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>
                </PropertysubGroup>

                <PropertysubGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Desmane: <span>{groups.group16.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group16.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />
                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Selección: <span>{groups.group17.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group17.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.IntCodigo != '451' && lab.IntCodigo != '119' ? lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    )) : lab.strUdp?.map(udp => (
                                                        udp.intCantidadTotal_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}
                                                    </PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Clasificación: <span>{groups.group30.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group30.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Sello y Peso: <span>{groups.group18.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group18.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Surtidor: <span>{groups.group32.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group32.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Empaque: <span>{groups.group31.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group31.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Palletizado: <span>{groups.group20.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group20.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp => (
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>
                    
                    <PropertyGroup><h2>#########</h2></PropertyGroup>
                </PropertysubGroup>
            </PropertyWorkerslistGroup>
        </FormControlModal>
    )
}
