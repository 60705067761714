import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/useAuth"
import { useRdt } from "../../../../hooks/useRdt";
import { getToken, removeToken } from "../../../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import swal from 'sweetalert';
import Alert from "../../../../components/Alert/Alert"


import { ButtonIcon } from "../../../../components/ButtonIcon"
import { IoCloseCircleOutline } from "react-icons/io5";
import { CerrarModal, FormControlModal, PropertyInfoWrapper, PropertyItemLab, PropertyTable, PropertyTableCell, PropertyTableCellRigth, PropertyTableHeader, PropertyTableHeaderRow, PropertyTableRow } from "./styles"
import { PropertyGroup, PropertyItem, PropertyItemButton, PropertyItemRdt, PropertysubGroup, PropertyWorkerslistGroup } from "./styles";



export const ModalRdtscorte = ({ setModalCuatro, animarModalcuatro, setAnimarModaldCuatro, sucursalSeleted, fechaini, groups }) => {

    //console.log({ sucursalModal: sucursalSeleted })
    //console.log({ fechaModal: fechaini })
    //console.log({ groups: groups })

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov } = useRdt();

    const [rdts, setRdts] = useState([]);
    const [tets, setTest] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getDataRdts = async () => {
            try {
                const token = getToken();
                if (!token) return
                setTimeout(() => {
                    getRdts();
                }, 200);

            } catch (error) {
                console.log(error)
            }
        }
        getDataRdts();
    }, [])
    useEffect(() => {
        if (groups && rdts.length > 0) {
            //getEmployeesRdts();
            getUpdatedGroups();
        }
    }, [groups, rdts]);
    //getRdts
    const getRdts = async () => {

        if (!fechaini) {
            setRdts([])
            return
            //console.log('Fecha vacia')
        }
        if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
            setRdts([])
            return
            //console.log({nohacerConsulta: sucursalSeleted})        
        } else {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/analyses',
                        params: makePropertiesFilters()
                    }
                );
                const { data } = response;
                data ? setRdts(data) : console.log('');
                //console.log({ dataRdtModal: data })

            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
            //console.log({hacerConsulta: sucursalSeleted})        
        }
    }
    const makePropertiesFilters = () => {
        const filters = {};
        filters['Fecha_INI'] = fechaini;
        filters['Fecha_FIN'] = fechaini;
        filters['IbmLaboro'] = sucursalSeleted;
        //console.log(fechaini);
        return filters;
    }
    //Close session if token expired
    const hendleCloseSeccion = async () => {
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente',
            "Su token puede estar vencido",
            SW_ICON.INFO,
            () => { navigate('/Login') }
        );
    }
    //Boton de cerrar Modal
    const ocultarModal = () => {
        //setAnimarModal(false)
        setModalCuatro(false)
    }
    //Funcion para sacar los Rdts de los empleados asignados al reparto
    const getUpdatedGroups = () => {
        // Iterar sobre cada grupo en el objeto `groups`
        Object.keys(groups).forEach(groupKey => {
            // Si el grupo tiene trabajadores
            if (groups[groupKey].length > 0) {
                groups[groupKey] = groups[groupKey].map(worker => {
                    // Buscar todas las labores reportadas del trabajador actual
                    const matchingRdts = rdts.filter(rdt => rdt.IntCodigo_EMPL === worker.IntCodigo);


                    // Formar el array de labores reportadas incluyendo `strUdp`
                    const laboresReportadas = matchingRdts.map(rdt => ({
                        IntCodigo: rdt.IntCodigo_CONC,
                        strConcepto: rdt.strConcepto,
                        intHora: rdt.intHora_RDT,
                        intTotal: rdt.intTotal_RDT,
                        observacion: rdt.strObservacion_RDT,
                        usuarioReg: rdt.email_USUA,
                        Udps: `${rdt.strNombre_UDP || ''}: ${rdt.intCantidad_UDP || ''}, ${rdt.strNombre_UDPdos || ''}: ${rdt.intCantidad_UDPdos || ''}, ${rdt.strNombre_UDPtres || ''}: ${rdt.intCantidad_UDPtres || ''}, ${rdt.strNombre_UDPcuatro || ''}: ${rdt.intCantidad_UDPcuatro || ''}`,
                        strUdp: rdt.strUdp.filter(udp => udp.IntCodigo_UDP && udp.IntCodigo_UDP !== "")
                    }));
                    // Añadir el array de labores al trabajador
                    return {
                        ...worker,
                        Labreportadas: laboresReportadas.length > 0 ? laboresReportadas : null
                    };
                });
            }
        });
        //console.log({ groupsUpdate: groups })
        return groups;
    };


    return (
        <FormControlModal>
            <CerrarModal>
                <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
            </CerrarModal>
            {/*CORTE*/}
            <PropertyWorkerslistGroup>
                <h2>CORTE: {groups.group1.length + groups.group2.length + groups.group3.length + groups.group4.length + groups.group5.length + groups.group6.length +
                        groups.group7.length + groups.group8.length + groups.group9.length + groups.group10.length + groups.group11.length + groups.group12.length +
                        (groups.group37 ? groups.group37.length : 0) + (groups.group38 ? groups.group38.length : 0) + (groups.group39 ? groups.group39.length : 0) +
                        (groups.group40 ? groups.group40.length : 0) + (groups.group41 ? groups.group41.length : 0) + (groups.group42 ? groups.group42.length : 0) +
                        (groups.group43 ? groups.group43.length : 0) + (groups.group44 ? groups.group44.length : 0) + (groups.group45 ? groups.group45.length : 0)}

                        <span>{fechaini}</span>
                </h2>
                <PropertysubGroup>
                    {/* CUADRILLA 1*/}
                    <PropertyGroup

                    >   
                        {/*Puyero*/}
                        <h2>CUADRILLA #1: {groups.group1.length + groups.group2.length + groups.group3.length}</h2>
                        <PropertyItemButton>
                        <h3>Puyero: <span>{groups.group1.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group1.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                        
                        <PropertyItemButton>
                        {/*Colero*/}
                        <h3>Coleros: <span>{groups.group2.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group2.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}  
                        {/*Garrucheros*/}
                        <PropertyItemButton>
                        <h3>Garrucheros: <span>{groups.group3.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group3.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                      
                    </PropertyGroup>
                    {/* CUADRILLA 2*/}
                    <PropertyGroup

                    >   
                        {/*Puyero*/}
                        <h2>CUADRILLA #2: {groups.group4.length + groups.group5.length + groups.group6.length}</h2>
                        <PropertyItemButton>
                        <h3>Puyero: <span>{groups.group4.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group4.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                        
                        <PropertyItemButton>
                        {/*Colero*/}
                        <h3>Coleros: <span>{groups.group5.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group5.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}  
                        {/*Garrucheros*/}
                        <PropertyItemButton>
                        <h3>Garrucheros: <span>{groups.group6.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group6.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                      
                    </PropertyGroup>
                    {/* CUADRILLA 3*/}
                    <PropertyGroup

                    >   
                        {/*Puyero*/}
                        <h2>CUADRILLA #3: {groups.group7.length + groups.group8.length + groups.group9.length}</h2>
                        <PropertyItemButton>
                        <h3>Puyero: <span>{groups.group7.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group7.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                        
                        <PropertyItemButton>
                        {/*Colero*/}
                        <h3>Coleros: <span>{groups.group8.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group8.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}  
                        {/*Garrucheros*/}
                        <PropertyItemButton>
                        <h3>Garrucheros: <span>{groups.group9.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group9.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                      
                    </PropertyGroup>
                    {/* CUADRILLA 4*/}
                    <PropertyGroup

                    >   
                        {/*Puyero*/}
                        <h2>CUADRILLA #4: {groups.group10.length + groups.group11.length + groups.group12.length}</h2>
                        <PropertyItemButton>
                        <h3>Puyero: <span>{groups.group10.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group10.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                        
                        <PropertyItemButton>
                        {/*Colero*/}
                        <h3>Coleros: <span>{groups.group11.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group11.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}  
                        {/*Garrucheros*/}
                        <PropertyItemButton>
                        <h3>Garrucheros: <span>{groups.group12.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group12.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                      
                    </PropertyGroup>
                    {/* CUADRILLA 5*/}
                    <PropertyGroup

                    >   
                        {/*Puyero*/}
                        <h2>CUADRILLA #5: {(groups.group37 ? groups.group37.length : 0) + (groups.group38 ? groups.group38.length : 0) + (groups.group39 ? groups.group39.length : 0)}</h2>
                        <PropertyItemButton>
                        <h3>Puyero: <span>{groups.group37.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group37.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                        
                        <PropertyItemButton>
                        {/*Colero*/}
                        <h3>Coleros: <span>{groups.group38.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group38.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}  
                        {/*Garrucheros*/}
                        <PropertyItemButton>
                        <h3>Garrucheros: <span>{groups.group39.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group39.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                      
                    </PropertyGroup>
                    {/* CUADRILLA 6*/}
                    <PropertyGroup

                    >   
                        {/*Puyero*/}
                        <h2>CUADRILLA #6: {(groups.group40 ? groups.group40.length : 0) + (groups.group41 ? groups.group41.length : 0) + (groups.group42 ? groups.group42.length : 0)}</h2>
                        <PropertyItemButton>
                        <h3>Puyero: <span>{groups.group40.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group40.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                        
                        <PropertyItemButton>
                        {/*Colero*/}
                        <h3>Coleros: <span>{groups.group41.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group41.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}  
                        {/*Garrucheros*/}
                        <PropertyItemButton>
                        <h3>Garrucheros: <span>{groups.group42.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group42.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                      
                    </PropertyGroup>
                    {/* CUADRILLA 7*/}
                    <PropertyGroup

                    >   
                        {/*Puyero*/}
                        <h2>CUADRILLA #7: {(groups.group43 ? groups.group43.length : 0) + (groups.group44 ? groups.group44.length : 0) + (groups.group45 ? groups.group45.length : 0)}</h2>
                        <PropertyItemButton>
                            <h3>Puyero: <span>{groups.group43.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group43.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                        
                        <PropertyItemButton>
                        {/*Colero*/}
                        <h3>Coleros: <span>{groups.group44.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group44.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}  
                        {/*Garrucheros*/}
                        <PropertyItemButton>
                        <h3>Garrucheros: <span>{groups.group45.length}</span></h3>
                        </PropertyItemButton>
                        {groups.group45.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}                      
                    </PropertyGroup>
                    <PropertyGroup><h2>#########</h2></PropertyGroup>
                </PropertysubGroup>
            </PropertyWorkerslistGroup>
        </FormControlModal>
    )
}
