import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/useAuth"
import { useRdt } from "../../../../hooks/useRdt";
import { getToken, removeToken } from "../../../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import swal from 'sweetalert';
import Alert from "../../../../components/Alert/Alert"


import { ButtonIcon } from "../../../../components/ButtonIcon"
import { IoCloseCircleOutline } from "react-icons/io5";
import { CerrarModal, FormControlModal, PropertyInfoWrapper, PropertyItemLab, PropertyTable, PropertyTableCell, PropertyTableCellRigth, PropertyTableHeader, PropertyTableHeaderRow, PropertyTableRow } from "./styles"
import { PropertyGroup, PropertyItem, PropertyItemButton, PropertyItemRdt, PropertysubGroup, PropertyWorkerslistGroup } from "./styles";



export const ModalRdtscampo = ({ setModalDos, animarModaldos, setAnimarModaldos, sucursalSeleted, fechaini, groups }) => {

    //console.log({ sucursalModal: sucursalSeleted })
    //console.log({ fechaModal: fechaini })
    //console.log({ groups: groups })

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov } = useRdt();

    const [rdts, setRdts] = useState([]);
    const [tets, setTest] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getDataRdts = async () => {
            try {
                const token = getToken();
                if (!token) return
                setTimeout(() => {
                    getRdts();
                }, 200);

            } catch (error) {
                console.log(error)
            }
        }
        getDataRdts();
    }, [])
    useEffect(() => {
        if (groups && rdts.length > 0) {
            //getEmployeesRdts();
            getUpdatedGroups();
        }
    }, [groups, rdts]);
    //getRdts
    const getRdts = async () => {

        if (!fechaini) {
            setRdts([])
            return
            //console.log('Fecha vacia')
        }
        if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
            setRdts([])
            return
            //console.log({nohacerConsulta: sucursalSeleted})        
        } else {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/analyses',
                        params: makePropertiesFilters()
                    }
                );
                const { data } = response;
                data ? setRdts(data) : console.log('');
                //console.log({ dataRdtModal: data })

            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
            //console.log({hacerConsulta: sucursalSeleted})        
        }
    }
    const makePropertiesFilters = () => {
        const filters = {};
        filters['Fecha_INI'] = fechaini;
        filters['Fecha_FIN'] = fechaini;
        filters['IbmLaboro'] = sucursalSeleted;
        //console.log(fechaini);
        return filters;
    }
    //Close session if token expired
    const hendleCloseSeccion = async () => {
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente',
            "Su token puede estar vencido",
            SW_ICON.INFO,
            () => { navigate('/Login') }
        );
    }
    //Boton de cerrar Modal
    const ocultarModal = () => {
        //setAnimarModal(false)
        setModalDos(false)
    }
    //Funcion para sacar los Rdts de los empleados asignados al reparto
    /*
    const getEmployeesRdts = async () => {
        console.log('Estoy ejecutando la funcion getEmployeesRdts')
        console.log({groups: groups})
        //console.log({rdts: rdts})
        //1. Pregunto si hay reparto
        if(groups){
            //console.log('El grupo esta con empleados asiganados')
            //Crear un array con todos los trabajadores asignados a los grupos
            const asigEmployees = Object.values(groups).reduce((acc, groupWorkers) => {
                return acc.concat(groupWorkers);
            }, []); // El array inicial está vacío
            //console.log({asigEmployees: asigEmployees})
            //Filtro los trabajadores asignados con RDT           
            if(rdts.length > 0){
                //console.log('estoy listo para filtrar los asigandos con rdts')
                // Filtrar empleados que tienen rdts
                const withRdts = rdts.filter((item) =>
                    asigEmployees.find((item2) => item.IntCodigo_EMPL === item2.IntCodigo)
                );               
                console.log({withRdts: withRdts})    
                // Cargar los grupos
                const loadedGroups = {};
                // Iterar sobre las propiedades del objeto groups
                Object.entries(groups).forEach(([groupName, workers]) => {
                    // Agregar la marcación si coincide el código del trabajador con los códigos rdts
                    const workersWithRdts = workers.map((worker) => {
                        const tieneRdt = withRdts.some(wRdts => wRdts.IntCodigo_EMPL === worker.IntCodigo);
                        //console.log({tieneRdt: tieneRdt.IntCodigo_CONC})
                        //console.log({worker: worker})
                        //console.log({wRdts: wRdts.IntCodigo_EMPL})
                    });
                    //loadedGroups[groupName] = workersWithMarcacion;
                });           
            }
        }
        //console.log({groups: groups.length > 0})
        //console.log({tets: tets})
    }*/
    const getUpdatedGroups = () => {
        // Iterar sobre cada grupo en el objeto `groups`
        Object.keys(groups).forEach(groupKey => {
            // Si el grupo tiene trabajadores
            if (groups[groupKey].length > 0) {
                groups[groupKey] = groups[groupKey].map(worker => {
                    // Buscar todas las labores reportadas del trabajador actual
                    const matchingRdts = rdts.filter(rdt => rdt.IntCodigo_EMPL === worker.IntCodigo);


                    // Formar el array de labores reportadas incluyendo `strUdp`
                    const laboresReportadas = matchingRdts.map(rdt => ({
                        IntCodigo: rdt.IntCodigo_CONC,
                        strConcepto: rdt.strConcepto,
                        intHora: rdt.intHora_RDT,
                        intTotal: rdt.intTotal_RDT,
                        observacion: rdt.strObservacion_RDT,
                        usuarioReg: rdt.email_USUA,
                        Udps: `${rdt.strNombre_UDP || ''}: ${rdt.intCantidad_UDP || ''}, ${rdt.strNombre_UDPdos || ''}: ${rdt.intCantidad_UDPdos || ''}, ${rdt.strNombre_UDPtres || ''}: ${rdt.intCantidad_UDPtres || ''}, ${rdt.strNombre_UDPcuatro || ''}: ${rdt.intCantidad_UDPcuatro || ''}`,
                        strUdp: rdt.strUdp.filter(udp => udp.IntCodigo_UDP && udp.IntCodigo_UDP !== "")
                    }));
                    // Añadir el array de labores al trabajador
                    return {
                        ...worker,
                        Labreportadas: laboresReportadas.length > 0 ? laboresReportadas : null
                    };
                });
            }
        });
        //console.log({ groupsUpdate: groups })
        return groups;
    };


    return (
        <FormControlModal>
            <CerrarModal>
                <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
            </CerrarModal>
            {/*CAMPO*/}
            <PropertyWorkerslistGroup>
                <h2>CAMPO: {groups.group21.length + groups.group22.length + groups.group23.length + groups.group24.length + groups.group25.length +
                    groups.group26.length + groups.group27.length + (groups.group47 ? groups.group47.length : 0)} <span>{fechaini}</span>

                </h2>
                <PropertysubGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Embolse: <span>{groups.group21.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group21.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>
                            </PropertyItem>
                        ))}
                        <br />
                    </PropertyGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Amarre: <span>{groups.group22.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group22.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />
                    </PropertyGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Desmache: <span>{groups.group23.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group23.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>
                </PropertysubGroup>

                <PropertysubGroup>
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Dcyp - Dyc: <span>{groups.group24.length}</span>

                            </h3>
                        </PropertyItemButton>
                        {groups.group24.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>
                    
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Protección Fruta: <span>{groups.group47 ? groups.group47.length : 0}</span>

                            </h3>
                        </PropertyItemButton>
                        {groups.group47 && groups.group47.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>
                    
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Manejo de Cobertura: <span>{groups.group25.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group25.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />
                    </PropertyGroup>
                    
                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Colocar Guantelete: <span>{groups.group26.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group26.map(worker => (
                            <PropertyItem
                                key={worker._id}

                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.IntCodigo != '451' && lab.IntCodigo != '119' ?lab.strUdp?.map(udp=>(
                                                        udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                        )):lab.strUdp?.map(udp=>(
                                                            udp.intCantidadTotal_UDP + ' ' + udp.strCinta_UDP + ', '
                                                        ))}
                                                    </PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>                                                           
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup

                    >
                        <PropertyItemButton>
                            <h3>Resiembras: <span>{groups.group27.length}</span>
                            </h3>
                        </PropertyItemButton>
                        {groups.group27.map(worker => (
                            <PropertyItem
                                key={worker._id}
                                centro={worker.strCodigoCentro}
                                marcacion={worker.marcacion}
                            >
                                <PropertyItemRdt>
                                    <PropertyInfoWrapper><h3>TRAB: {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</h3></PropertyInfoWrapper>
                                    <PropertyTable>
                                        <thead>
                                            <PropertyTableRow>
                                                <PropertyTableCell>Cod</PropertyTableCell>
                                                <PropertyTableCell>Labor</PropertyTableCell>
                                                <PropertyTableCell>Horas</PropertyTableCell>
                                                <PropertyTableCell>Total</PropertyTableCell>
                                                <PropertyTableCell>Udps</PropertyTableCell>
                                                <PropertyTableCell>Cintas</PropertyTableCell>
                                                <PropertyTableCell>Observación</PropertyTableCell>
                                                <PropertyTableCell>Reporta</PropertyTableCell>
                                            </PropertyTableRow>
                                        </thead>
                                        <tbody>
                                            {worker.Labreportadas?.map(lab => (
                                                <PropertyTableRow>
                                                    <PropertyTableCellRigth>{lab.IntCodigo}</PropertyTableCellRigth>
                                                    <PropertyTableCell>{lab.strConcepto}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.intHora}</PropertyTableCell>
                                                    <PropertyTableCell>{(lab.intTotal).toFixed(2)}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.Udps}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.strUdp?.map(udp=>(
                                                       udp.intCantidad_UDP + ' ' + udp.strCinta_UDP + ', '
                                                    ))}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.observacion}</PropertyTableCell>
                                                    <PropertyTableCell>{lab.usuarioReg.split('@')[0]}</PropertyTableCell>
                                                </PropertyTableRow>
                                            ))}
                                        </tbody>
                                    </PropertyTable>
                                </PropertyItemRdt>

                            </PropertyItem>
                        ))}
                        <br />

                    </PropertyGroup>

                    <PropertyGroup><h2>#########</h2></PropertyGroup>
                </PropertysubGroup>                
            </PropertyWorkerslistGroup>
        </FormControlModal>
    )
}
