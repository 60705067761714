import {
    BrowserRouter,
    Routes,
    Route

} from 'react-router-dom'

import { AuthProvider } from './context/AuthProvider'
import { RdtProvider } from './context/RdtProvider'
import { Home } from './pages/Home'
import { Account } from './pages/Account'
import { Login } from './pages/Login'
//import { Signup } from './pages/Signup'
import { ForgetPassword } from './pages/ForgetPassword'
import { ConfirmAccount } from './pages/ConfirmAccount'
import { NewPassword } from './pages/NewPassword'
import { News } from './pages/News'
import { AddNews } from './pages/AddNews'
import { NotFound } from './pages/NotFound'
import { Retired } from './pages/Retired'
import { NoBiometrics } from './pages/NoBiometrics'
import { TraslateDetail } from './pages/Traslates/Components/TraslateDetail'
import { RetiredDetail } from './pages/Retired/Components/RetiredDetail'
import { Rdts } from './pages/Rdts'
import { AddRdts } from './pages/AddRdts'
import { RdtDetail } from './pages/Rdts/RdtDetail'
import { RdtDuplicate } from './pages/Rdts/RdtDuplicate'
//import { RdtExport } from './pages/Rdts/RdtExport'
//import { Sincronizations } from './pages/Sincronizations'
//import { Sincronizationsnom } from './pages/Sincronizationsnom'
import { Rdtsanalyses } from './pages/Rdtsanalyses'
import { AddRdtsnews } from './pages/AddRdtsnews'
import { Rdtsnews } from './pages/Rdtsnews'
import { RdtnewsDetail } from './pages/Rdtsnews/RdtnewsDetail'
import { AddRdds } from './pages/AddRdds'
import { Rdds } from './pages/Rdds'
import { EmployeesDial } from './pages/EmployeesDials'
import { Rdtauthorizations } from './pages/Rdtsauthorizations'
import { AddPrecipitation } from './pages/AddPrecipitation'
import { PrecipitationDetail } from './pages/Precipitations/PrecipitationDetail'
import { Precipitations } from './pages/Precipitations'
import { AddReceptionProducts } from './pages/AddReceptionProducts'
import { ReceptionProducts } from './pages/ReceptionProducts'
import { ReceptionProductDetail } from './pages/ReceptionProducts/Components/ReceptionProductDetail'
import { AddTransport } from './pages/AddTransport'
import { TransportDetail } from './pages/Transports/Components/TransportDetail'
import { AddDistribution } from './pages/AddDistribution'
import { RdtDetailDistribution } from './pages/AddRdtsDistribution/RdtDetailDistribution'
import { Testpage } from './pages/Testpage'
import { RdtDuplicateDistribution } from './pages/AddRdtsDistribution/RdtDuplicateDistribution'

//<Route path='/Signup' element={<Signup />} />
//<Route path='/RdtExport' element={<RdtExport />} />
//<Route path='/Sincronizations' element={<Sincronizations/>} /> 
//<Route path='/Sincronizationsnom' element={<Sincronizationsnom/>} />
//<Route path='/Homegc' element={<Homegc />}/>

//import { Homegc } from './pages/Homegc'
//<Route path='/Testpage' element={<Testpage />} />

export const Navigation = () => (
    <BrowserRouter>
        <AuthProvider>
            <RdtProvider>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/Account' element={<Account />} />
                    <Route path='/Login' element={<Login />} />

                    <Route path='/ForgetPassword' element={<ForgetPassword />} />
                    <Route path='/ConfirmAccount/:id' element={<ConfirmAccount />} />
                    <Route path='/ForgetPassword/:token' element={<NewPassword />} />
                    <Route path='/News' element={<News />} />
                    <Route path='/AddNews' element={<AddNews />} />
                    <Route path='/TraslateDetail/:id' element={<TraslateDetail />} />
                    <Route path='/Retired' element={<Retired />} />
                    <Route path='/RetiredDetail/:id' element={<RetiredDetail />} />
                    <Route path='/Nobiometrics' element={<NoBiometrics />} />
                    <Route path='/Rdts' element={<Rdts />} />
                    <Route path='/Rdtsanalyses' element={<Rdtsanalyses />} />
                    <Route path='/AddRdts' element={<AddRdts />} />
                    <Route path='/RdtDetail/:id' element={<RdtDetail />} />
                    <Route path='/RdtDuplicate/:id' element={<RdtDuplicate />} />
                    <Route path='/AddRdtsnews' element={<AddRdtsnews />} />
                    <Route path='/Rdtsnews' element={<Rdtsnews />} />
                    <Route path='/RdtnewsDetail/:id' element={<RdtnewsDetail />} />
                    <Route path='/AddRdds' element={<AddRdds />} />
                    <Route path='/Rdds' element={<Rdds />} />

                    <Route path='/Employeesdials' element={<EmployeesDial />} />
                    <Route path='/Rdtauth' element={<Rdtauthorizations />} />
                    <Route path='/AddPrecipitation' element={<AddPrecipitation />} />
                    <Route path='/PrecipitationDetail/:id' element={<PrecipitationDetail />} />
                    <Route path='/Precipitations' element={<Precipitations />} />
                    <Route path='/AddReceptionProducts' element={<AddReceptionProducts />} />
                    <Route path='/ReceptionProducts' element={<ReceptionProducts />} />
                    <Route path='/ReceptionProductDetail/:id' element={<ReceptionProductDetail />} />
                    <Route path='/AddTransport' element={<AddTransport />} />
                    <Route path='/TransportDetail/:id' element={<TransportDetail />} />
                    <Route path='/AddDistribution' element={<AddDistribution />} />
                    <Route path='/RdtDetailDistribution/:id' element={<RdtDetailDistribution />} />
                    <Route path='/RdtDuplicateDistribution/:id' element={<RdtDuplicateDistribution />} />

                    <Route path='*' element={<NotFound />} />
                </Routes>
            </RdtProvider>
        </AuthProvider>
    </BrowserRouter>
)