import { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { useRdt } from "../../hooks/useRdt";
import { Page } from '../../components/Page';
import { LogoImageWrapper } from '../../components/Logo/styles';
import { PageTitle } from '../../globalStyles';
import { PropertyTypeButton } from '../Home/components/PropertyTypeButton';
import {
    IoBusiness, IoExit, IoPersonCircleOutline,
    IoHome, IoMap, IoPrism, IoLocation,
    IoPeopleOutline, IoCreateOutline, IoDocumentText,
    IoSearch, IoDownload, IoRainy, IoReceipt, IoBus
} from "react-icons/io5";
import { NewsWrapper, NewsWrapper_Global, NewsWrapper_Global_DOS } from "./styles";

const PropertiesTypes = [
    { id: 1, icon: IoBusiness, label: 'Traslados' },
    { id: 2, icon: IoExit, label: 'Retiros' },
    { id: 3, icon: IoPersonCircleOutline, label: 'Sin Biometria' },
    { id: 14, icon: IoRainy, label: 'Precipitación' },
    { id: 4, icon: IoCreateOutline, label: 'Registrar Rdts' },
    { id: 5, icon: IoSearch, label: 'Consultar Rdts' },
    //{ id: 6, icon: IoDownload, label:'Exportar Rdts'}, 
    { id: 7, icon: IoDocumentText, label: 'Analisis Rdts' },
    { id: 8, icon: IoCreateOutline, label: 'Registrar Nov Rdts' },
    { id: 9, icon: IoSearch, label: 'Consultar Nov Rdts' },
    { id: 10, icon: IoCreateOutline, label: 'Registrar Casinos' },
    { id: 11, icon: IoSearch, label: 'Consultar Casinos' },
    { id: 12, icon: IoDocumentText, label: 'Analisis Horarios' },
    { id: 13, icon: IoSearch, label: 'Autorizaciones Rdts' },
    { id: 15, icon: IoReceipt, label: 'Recep Fertilizantes' },
    { id: 16, icon: IoBus, label: 'Transporte' },
    { id: 17, icon: IoDocumentText, label: 'Analisis Fertilizantes' },
    { id: 18, icon: IoCreateOutline, label: 'Registrar Reparto' },
];

export const News = () => {

    const { auth } = useAuth();
    const { rdtprov, setRdtprov } = useRdt();
    //console.log(auth.data?auth.data.ibm:4)         
    const navigate = useNavigate();

    const [propertyTypeSeleted, setPropertyTypeSelected] = useState();

    const propertyTypeHandler = (id) => {
        setPropertyTypeSelected(id);
    }

    useEffect(() => {
        if (auth.data.ibm) {
            const rdtData = {
                ibm: auth.data.ibm,
            };
            setRdtprov(rdtData)
            //console.log({rdtdata: rdtData})
        }
    }, [])

    useEffect(() => {
        const datas = {
            propertyType: propertyTypeSeleted
        };
        if (auth.data.role === 1 || auth.data.role === 2) {
            if (propertyTypeSeleted === 1) {
                { navigate('/addNews') }
            } else if (propertyTypeSeleted === 2) {
                { navigate('/Retired') }
            } else if (propertyTypeSeleted === 3) {
                { navigate('/Nobiometrics') }
            } else if (propertyTypeSeleted === 4) {
                { navigate('/addRdts') }
            } else if (propertyTypeSeleted === 5) {
                { navigate('/Rdts') }
            }/*else if( propertyTypeSeleted === 6){
                { navigate('/Rdtexport')}
            }*/else if (propertyTypeSeleted === 7) {
                { navigate('/Rdtsanalyses') }
            } else if (propertyTypeSeleted === 8) {
                { navigate('/addRdtsnews') }
            } else if (propertyTypeSeleted === 9) {
                { navigate('/Rdtsnews') }
            } else if (propertyTypeSeleted === 10) {
                { navigate('/addRdds') }
            } else if (propertyTypeSeleted === 11) {
                { navigate('/Rdds') }
            } else if (propertyTypeSeleted === 12) {
                { navigate('/Employeesdials') }
            } else if (propertyTypeSeleted === 13) {
                { navigate('/Rdtauth') }
            } else if (propertyTypeSeleted === 14) {
                { navigate('/AddPrecipitation') }
            } else if (propertyTypeSeleted === 15) {
                { navigate('/AddReceptionProducts') }
            } else if (propertyTypeSeleted === 16) {
                { navigate('/AddTransport') }
            } else if (propertyTypeSeleted === 17) {
                { navigate('/ReceptionProducts') }
            } else if (propertyTypeSeleted === 18) {
                { navigate('/AddDistribution') }
            }
        } else if (auth.data.role === 3) {
            if (propertyTypeSeleted === 1) {
                { navigate('/addNews') }
            } else if (propertyTypeSeleted === 2) {
                { navigate('/Retired') }
            } else if (propertyTypeSeleted === 3) {
                { navigate('/Nobiometrics') }
            } else if (propertyTypeSeleted === 4) {
                { navigate('/addRdts') }
            } else if (propertyTypeSeleted === 5) {
                { navigate('/Rdts') }
            } else if (propertyTypeSeleted === 7) {
                { navigate('/Rdtsanalyses') }
            } else if (propertyTypeSeleted === 8) {
                { navigate('/addRdtsnews') }
            } else if (propertyTypeSeleted === 9) {
                { navigate('/Rdtsnews') }
            } else if (propertyTypeSeleted === 10) {
                { navigate('/addRdds') }
            } else if (propertyTypeSeleted === 11) {
                { navigate('/Rdds') }
            } else if (propertyTypeSeleted === 12) {
                { navigate('/Employeesdials') }
            } else if (propertyTypeSeleted === 13) {
                { navigate('/Rdtauth') }
            } else if (propertyTypeSeleted === 14) {
                { navigate('/AddPrecipitation') }
            } else if (propertyTypeSeleted === 15) {
                { navigate('/AddReceptionProducts') }
            } else if (propertyTypeSeleted === 17) {
                { navigate('/ReceptionProducts') }
            } else if (propertyTypeSeleted === 18) {
                { navigate('/AddDistribution') }
            }
        }
    }, [propertyTypeSeleted])

    return (
        <Page>
            <NewsWrapper_Global>
                <NewsWrapper_Global_DOS>
                    <LogoImageWrapper>
                        <PageTitle>
                            NOVEDADES
                        </PageTitle>
                        <img src={"Logo.jpg"} alt="imgLogo" />
                    </LogoImageWrapper>
                    <NewsWrapper>
                        {
                            PropertiesTypes.map(item =>
                                <PropertyTypeButton
                                    selected={propertyTypeSeleted === item.id}
                                    key={item.id}
                                    icon={item.icon}
                                    label={item.label}
                                    id={item.id}
                                    onPress={propertyTypeHandler}
                                />)
                        }
                    </NewsWrapper>

                </NewsWrapper_Global_DOS>
            </NewsWrapper_Global>
        </Page>
    )
}