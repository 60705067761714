import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth"
import { useRdt } from "../../hooks/useRdt";
import { getToken, removeToken } from "../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import swal from 'sweetalert';
import Alert from "../../components/Alert/Alert"
import { FormControl, FormControlInput, SubTitle } from "../../globalStyles";
import { Page } from "../../components/Page";
import {
    PropertyTypesContainerColum,
    PropertyTypesContainerHead,
    PropertyTypesContainerRow,
    PropertyInfoWrapper,
    DistributionDetailTitle,
    DistributionDetailInfo,
    PropertyApp,
    PropertyWorkerslistGroup,
    PropertyGroup,
    PropertysubGroup,
    PropertyWorker,
    PropertyItem,
    FormControlInputSearch,
    PropertyItemButton,
    TextareaContainer,
    ObservationsTextarea,
    PropertyContainerInfo,
    PropertyContainerInfoCajas,
    PropertyTypesContainerColumGroup,
    PropertyTypesContainerRowGroup,
    PropertyTypesContainerRowGroupG,
    PropertyItemRdt,
    ButtonAplicate,
    PropertyItemInfo,
} from "./styles";
import { PropertyProfile } from "../Home/components/PropertyProfile";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { ButtonIconRemove } from "../../components/ButtonIconRemove";
import {
    IoTrashOutline,
    IoRemoveCircleOutline,
    IoRadioButtonOffOutline,
    IoArrowBackCircleOutline,
    IoArrowBack,
    IoDuplicate,
    IoAddCircleOutline,
    IoSave,
    IoSend,
    IoRemove
} from "react-icons/io5";
import { ButtonIcon } from "../../components/ButtonIcon";
import { Button } from "../../components/Button";
import { PropertyCardDialsm } from "../EmployeesDials/PropertyCardDialsm";
import { PropertyCardDial } from "../EmployeesDials/PropertyCardDial";
import { AuthorizationsRdts } from "../AddRdts/components/AuthorizationsRdts";
import { AddRdtsDistribution } from "../AddRdtsDistribution";
import { ModalRdtscampo } from "./components/ModalRdtscampo";
import { ModalRdtsotros } from "./components/ModalRdtsotros";
import { ModalRdtscorte } from "./components/ModalRdtscorte";
import { ModalRdtsempaque } from "./components/ModalRdtsempaque";


const ALL_SUCURSALES = 0;
const fecha = Date.now();
const suma = 0;
const Trab = 0;

export const AddDistribution = () => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, rdtEmploye } = useRdt();

    const [sucursal, setSucursal] = useState([]);
    const [sucursalname, setSucursalname] = useState('');
    const [employees, setEmployees] = useState([]);
    const [distribution, setDistribution] = useState([]);
    const [employeesnoasignados, setEmployeesNoasignados] = useState([]);
    const [employeesCF, setEmployeesCF] = useState([]);

    const [employeesdials, setEmployeesdials] = useState([]);

    const [traslate, setTraslate] = useState([]);
    const [traslateGlobal, setTraslateGlobal] = useState([]);
    const [viewTraslate, setViewTraslate] = useState([]);

    const [news, setNews] = useState([]);
    const [newssort, setNewssort] = useState([]);
    const [totalemployees, setTotalEmployees] = useState([]);

    const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
    const [codtrabajador, setCodtrabajador] = useState('');
    const [fechaini, setFechaIni] = useState('');
    const [sumcajas, setSumcajas] = useState(0);

    const [observations, setObservations] = useState('');

    const [rdts, setRdts] = useState([]);

    const [modald, setModalD] = useState(false);
    const [animarModald, setAnimarModalD] = useState(false);

    const [modaldos, setModalDos] = useState(false); //Modal para visualizar los Rdts reportados campo.
    const [animarModaldos, setAnimarModalDos] = useState(false);

    const [modaltres, setModalTres] = useState(false); //Modal para visualizar los Rdts reportados otros.
    const [animarModaltres, setAnimarModalTres] = useState(false);

    const [modalcuatro, setModalCuatro] = useState(false); //Modal para visualizar los Rdts reportados corte.
    const [animarModalcuatro, setAnimarModalCuatro] = useState(false);

    const [modalcinco, setModalCinco] = useState(false); //Modal para visualizar los Rdts reportados empaque.
    const [animarModalcinco, setAnimarModalCinco] = useState(false);

    const [namerepart, setNamerepart] = useState({});

    //Para el manejo de asignación de grupos
    const [groups, setGroups] = useState({
        group1: [], // Puyero C1
        group2: [], // colero C1
        group3: [], // garruchero C1
        group4: [], // Puyero C2
        group5: [], // colero C2
        group6: [], // garruchero C2
        group7: [], // Puyero C3
        group8: [], // colero C3
        group9: [], // garruchero C3
        group10: [], // Puyero C4
        group11: [], // colero C4
        group12: [], // garruchero C4
        group13: [], // Barcadilla
        group14: [], // Guantelete
        group15: [], // Cochinilla
        group16: [], // Desmane
        group17: [], // Seleccion
        group18: [], // Sellador
        group19: [], // Pagada de Cajas 
        group20: [], // Paletizado
        group21: [], // Embolse
        group22: [], // Amarre
        group23: [], // Desmache
        group24: [], // Mix
        group25: [], // Manejo de Cobertura
        group26: [], // Amarre
        group27: [], // Desmache
        group28: [], // Coordinadores Campo y Empaque
        group29: [], // Coordinador - Almacenista
        group30: [], // Clasificación
        group31: [], // Empaque
        group32: [], // Surtidor
        group33: [], // Labores Varias
        group34: [], // Reubicado
        group35: [], // Cablero
        group36: [], // Repique
        group37: [], // Puyero C5
        group38: [], // colero C5
        group39: [], // garruchero C5
        group40: [], // Puyero C6
        group41: [], // colero C6
        group42: [], // garruchero C6
        group43: [], // Puyero C7
        group44: [], // colero C7
        group45: [], // garruchero C7
        group46: [], // Operadores Retro
        group47: [], // Proteccion Fruta
        group48: [] //  Bolsa MdoNal
    });

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
    });

    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await getSucursalesAll();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [auth])
    //Si estoy editando
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await rdtprov.ibm ? setSucursalSelected(rdtprov.ibm) : null();
                //await rdtprov.dateRdt?setDate((rdtprov.dateRdt).split('T')[0]):null();                               
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [rdtprov])
    /*//Sucursals       
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                sucursalSeleted ? setEmployees([]) : console.log('');
                sucursalSeleted ? getEmployeesAll() : console.log('');
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [sucursalSeleted])
    //date and sucursals
    useEffect(() => {
        const getDataDistribution = async () => {
            try {
                const token = getToken();
                if (!token) return
                setEmployees([]); 
                setDistribution([]);
                setTraslate([]);
                setViewTraslate([]);
                setNews([]);
                setEmployeesCF([]);
                setEmployeesdials([]);
                setObservations('');
                await getEmployeesAll();                             
                await getDistribution();
                await getTraslatesAll();
                await getTraslatesGlobal();
                await getNews();
                await sucursalSeleted ? getEmployeesdialsAll() : console.log('');                
                //await getEmployeesNoasignadosNews();             
            } catch (error) {
                console.log(error)
            }
        }
        getDataDistribution();
    }, [fechaini, sucursalSeleted])*/
    // useEffect combinado
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getToken();
                if (!token) return;

                // Limpiar datos anteriores
                setEmployees([]);
                setDistribution([]);
                setTraslate([]);
                setViewTraslate([]);
                setNews([]);
                setEmployeesCF([]);
                setEmployeesdials([]);
                setObservations('');

                if (sucursalSeleted) {
                    await getEmployeesAll();
                    await getDistribution();
                    await getTraslatesAll();
                    await getTraslatesGlobal();
                    await getNews();
                    await getRdts();
                    await getEmployeesdialsAll();
                    // Ejecutar la función adicional después de que las anteriores se completen
                    //await getEmployeesNoasignadosNews();
                } else {
                    console.log('No se seleccionó sucursal.');
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        // Solo ejecutar si ambos estados están definidos
        if (sucursalSeleted || fechaini) {
            fetchData();
        }
    }, [sucursalSeleted, fechaini]); // Dependencias del useEffect
    //news
    useEffect(() => {
        const getDataDistributionNoasignados = async () => {
            try {
                const token = getToken();
                if (!token) return
                await getEmployeesNoasignadosNews();
            } catch (error) {
                console.log(error)
            }
        }
        getDataDistributionNoasignados();
    }, [news])
    //traslates
    useEffect(() => {
        const getDataTraslatesNoasignados = async () => {
            try {
                const token = getToken();
                if (!token) return
                //await getEmployeesoutTraslates(); 
            } catch (error) {
                console.log(error)
            }
        }
        getDataTraslatesNoasignados();
    }, [viewTraslate])
    //dials
    useEffect(() => {
        const getDataDistributiondials = async () => {
            try {
                const token = getToken();
                if (!token) return
                await getEmployeesdialsData();
            } catch (error) {
                console.log(error)
            }
        }
        getDataDistributiondials();
    }, [employeesdials])
    //Close session if token expired
    const hendleCloseSeccion = async () => {
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente',
            "Su token puede estar vencido",
            SW_ICON.INFO,
            () => { navigate('/Login') }
        );
    }
    //Sucursals
    const getSucursalesAll = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',
                    params: makeSucursalesFilters()
                }
            );
            const { data } = response;
            //console.log({sucursals: data})                
            setSucursal(data);
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = () => {
        const filters = {};
        return filters;
    }
    //EmployeesAllnombre     
    const getEmployeesAll = async () => {
        if (sucursalSeleted == '90021') {
            return;
        }
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',
                    params: makeEmployeesFiltersAll()
                }
            );
            const { data } = response;
            //console.log({ employees: data })
            //console.log({Totalemployees: data})
            setEmployees(data);
            setTotalEmployees(data);
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeEmployeesFiltersAll = () => {
        const filters = {};
        filters['Ibm'] = sucursalSeleted;
        if ((auth.data.role !== 1) && (auth.data.role !== 2)) {
            filters['Ibm'] = auth.data.ibm;
        }
        //console.log({ filterssucursalSeleted: sucursalSeleted });
        //console.log({ filtersEmployeesAll: filters });
        return filters;
    }
    //getDistribution
    const getDistribution = async () => {
        //console.log({sucursal: sucursalSeleted})
        if (!fechaini) {
            //setRdts([])
            return
            //console.log('Fecha vacia')
        }
        if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
            //setRdts([])
            return
            //console.log({nohacerConsulta: sucursalSeleted})        
        } else {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/personalDistributions',
                        params: makePropertiesFilters()
                    }
                );
                const { data } = response;
                const reparto = data ? response.data : '';
                data ? setDistribution(data) : console.log('');
                //console.log({distribution: distribution});
                if (reparto[0]) {
                    //console.log('Cargando..')
                    // Cargar los grupos
                    const loadedGroups = {};
                    const loadedReparts = {};
                    reparto[0].groups.forEach(group => {
                        loadedGroups[group.name] = group.workers;
                        loadedReparts[group.name] = group.reparto;
                    });
                    setGroups(loadedGroups);
                    setNamerepart(loadedReparts);
                    //console.log({Groups: loadedGroups});
                    //console.log({Reparts: loadedReparts});

                    //Cargar los empleados no asignados
                    setEmployeesNoasignados(reparto[0].workersnoAsignados);
                    //console.log({workersnoAsignados: reparto[0].workersnoAsignados.length >0?reparto[0].workersnoAsignados:getEmployeesAll()});

                    //console.log({repartoData: reparto[0]})
                    setSumcajas(reparto[0].intCajasProgramadas);

                    setObservations(reparto[0].strObservacion_RDT);


                } else {
                    // Si no se encuentra un reparto para la fecha, limpiamos los grupos
                    setGroups(
                        {
                            group1: [], // Puyero C1
                            group2: [], // colero C1
                            group3: [], // garruchero C1
                            group4: [], // Puyero C2
                            group5: [], // colero C2
                            group6: [], // garruchero C2
                            group7: [], // Puyero C3
                            group8: [], // colero C3
                            group9: [], // garruchero C3
                            group10: [], // Puyero C4
                            group11: [], // colero C4
                            group12: [], // garruchero C4
                            group13: [], // Barcadilla
                            group14: [], // Guantelete
                            group15: [], // Cochinilla
                            group16: [], // Desmane
                            group17: [], // Seleccion
                            group18: [], // Sellador
                            group19: [], // Pagada de Cajas 
                            group20: [], // Paletizado
                            group21: [], // Embolse
                            group22: [], // Amarre
                            group23: [], // Desmache
                            group24: [], // Mix
                            group25: [], // Manejo de Cobertura
                            group26: [], // Amarre
                            group27: [], // Desmache
                            group28: [], // Coordinadores Campo y Empaque
                            group29: [], // Coordinador - Almacenista
                            group30: [], // Clasificación
                            group31: [], // Empaque
                            group32: [], // Surtidor
                            group33: [], // Labores Varias
                            group34: [], // Reubicado
                            group35: [], // Cablero
                            group36: [], // Repique
                            group37: [], // Puyero C5
                            group38: [], // colero C5
                            group39: [], // garruchero C5
                            group40: [], // Puyero C6
                            group41: [], // colero C6
                            group42: [], // garruchero C6
                            group43: [], // Puyero C7
                            group44: [], // colero C7
                            group45: [], // garruchero C7
                            group46: [], // Operadores Retro
                            group47: [], // Proteccion Fruta
                            group48: [] //  Bolsa MdoNal
                        }
                    );
                    setSumcajas(0);
                    //getEmployeesAll();
                    //console.log('No hay data')
                }


            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
            //console.log({hacerConsulta: sucursalSeleted})        
        }
    }
    const makePropertiesFilters = () => {
        const filters = {};
        filters['Fecha_INI'] = fechaini;
        filters['Fecha_FIN'] = fechaini;
        filters['strCodigo_SUCU'] = sucursalSeleted;
        // filters['IntCodigo_EMPL'] = codtrabajador;    
        // filters['IntCodigo_CONC'] = codlabor;
        // filters['IntCodigo_UDP'] = codudp;
        // filters['employeesId'] = auth.data._id;     
        //console.log(fechaini);
        return filters;
    }
    //getTraslates
    const getTraslatesAll = async () => {
        if (!fechaini) {
            return
            //console.log('Fecha vacia')
        }
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/traslates/rdts',
                    params: makePropertiesFiltersTraslates()
                }
            );
            const { data } = response;
            //console.log({traslatesOtrasFincas: response})                
            setTraslate(data);
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makePropertiesFiltersTraslates = () => {
        const filters = {};
        filters['dtFInicial_RDT'] = fechaini;
        filters['dtFFinal_RDT'] = fechaini;
        filters['intIBM_TRAS'] = sucursalSeleted;
        return filters;
    }
    //getTraslatesGlobal
    const getTraslatesGlobal = async () => {
        if (!fechaini) {
            return
            //console.log('Fecha vacia')
        }
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/traslates',
                    //params: makePropertiesFiltersTraslates()                                     
                }
            );
            const { data } = response;
            //console.log({traslatesGlobal: response})                
            setTraslateGlobal(data);
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    //getNews
    const getNews = async () => {
        //console.log({sucursal: sucursalSeleted})
        if (!fechaini) {
            //setNews([])
            return
            //console.log('Fecha vacia')
        }

        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/rdtsnews',
                    params: makePropertiesFiltersNews()
                }
            );
            const { data } = response;
            const sortedNews = [...data].sort((a, b) => a.strConcepto.localeCompare(b.strConcepto));
            //console.log({sortedNews: sortedNews});                                                               
            data ? setNews(sortedNews) : console.log('');
            //console.log({news: data});                                      
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }

        //console.log({hacerConsulta: sucursalSeleted})        

    }
    const makePropertiesFiltersNews = () => {
        const filters = {};
        filters['Fecha_INI'] = fechaini;
        filters['Fecha_FIN'] = fechaini;
        filters['strCodigo_SUCU'] = sucursalSeleted;
        //console.log({filtersNews: filters})
        return filters;
    }
    //getRdts
    const getRdts = async () => {

        if (!fechaini) {
            setRdts([])
            return
            //console.log('Fecha vacia')
        }
        if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
            setRdts([])
            return
            //console.log({nohacerConsulta: sucursalSeleted})        
        } else {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/analyses',
                        params: makePropertiesFiltersrdts()
                    }
                );
                const { data } = response;
                data ? setRdts(data) : console.log('');
                //console.log({ dataRdtDistribution: data })

            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
            //console.log({hacerConsulta: sucursalSeleted})        
        }
    }
    const makePropertiesFiltersrdts = () => {
        const filters = {};
        filters['Fecha_INI'] = fechaini;
        filters['Fecha_FIN'] = fechaini;
        filters['IbmLaboro'] = sucursalSeleted;
        //console.log(fechaini);
        return filters;
    }
    //EmployeesDials
    const getEmployeesdialsAll = async () => {
        if (fechaini) {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/employeesdials',
                        params: makeEmployeesdialsFiltersAll()
                    }
                );
                const { data } = response;
                //console.log({employeesdial: data})                       
                await setEmployeesdials(data);
            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
        } else {
            return;
        }
    }
    const makeEmployeesdialsFiltersAll = () => {
        const filters = {};
        filters['dtFecha'] = fechaini;
        //filters['Ibm'] = sucursalSeleted;      
        //console.log(fechaini,sucursalSeleted)
        return filters;
    }
    //handle Sucursals
    const handleChange = event => {
        setSucursalSelected(event.target.value)
    }
    const handleChangeDate = event => {
        setFechaIni(event.target.value)
    }
    //Validar dia de la semana
    const fechaComoCadena = fechaini.split('T')[0] + " 00:01:59"; // día semana 
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];
    //Para sacar las novedades de los noAsignados
    const getEmployeesNoasignadosNews = async () => {

        const formatDate = (timestamp) => {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0 indexados
            const year = date.getFullYear();

            return `${year}-${month}-${day}`;

            // Formatear hora
            /*
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
            */
        };
        let currentDate = formatDate(Date.now());

        let sinNews = {}; //Sin novedades
        let traslateGlobalesFincanoAsignados = {}; //Para sacar los Personal prestado de otras fincas de no asignados
        let sinNewsyTraslates = {}; //Sin novedades y traslados de mi misma finca de los no asignados
        let sinNewsyTraslatesyPOF = {}; // Sin novedades y traslados de mi misma finca + Personal de otras fincas de los no asignados
        let sinNewsyTraslatesyPOFyCF = {}; //Sin novedades,traslados,prestamo de otras fincas y cuadrilla fertilizacion de los no asignados
        let sinNewsyTraslatesyPOFyDistribution = {}; //Sin novedades,traslados,prestamo de otras fincas + el personal con reparto de los no asignados
        //console.log('Estoy dentro de getEmployeesNoasignadosNews');       

        //console.log({currentDate: currentDate}); // Muestra la fecha en formato día-mes-año
        //console.log({fechaini: fechaini}); //Muestra la fecha seleccionada
        if (fechaini >= currentDate) {
            //console.log('fechas igual o mayor a la actual');
            //1.Pregunto si hay novedades, si hay las saco de los empleados habilitados para asignar
            if (news.length > 0) {
                //console.log('Hay novedades')
                //Saco las novedades de los workers no asiganados                                                       
                sinNews = employees.filter((item) => !news.find((item2) => item.IntCodigo === item2.IntCodigo_EMPL));
                //console.log({sinNews: sinNews})
                //setEmployees(sinNews)
            } else {
                //Si no hay novedades a sinNews le asigno todos los empleados activos   
                //console.log('No hay Novedades')
                sinNews = employees;
                //console.log({sinNews: sinNews})                                                             
            }

            //2. Pregunto si hay traslados y prestamos de la finca
            if (traslateGlobal.length > 0) {
                //console.log('Hay traslados de la finca')                
                //console.log({traslateGlobal: traslateGlobal})
                //Filtrar los traslados y prestamos Globales de la finca            
                const traslateGlobalesFinca = traslateGlobal.filter((item) => item.employeesId.ibm === sucursalSeleted);
                //console.log({traslateGlobalesFinca: traslateGlobalesFinca})

                //Filtrar los prestamos o traslados y sacarlos de los globales para mostrarlos aparte.                                                       
                traslateGlobalesFincanoAsignados = traslateGlobalesFinca.filter((item) => (item.dtFFinal >= fechaini + 'T00:00:00.000Z') || //Para Prestamos
                    (item.dtFFinal === null)); //Para Traslados
                //console.log({traslateGlobalesFincanoAsignados: traslateGlobalesFincanoAsignados})
                //Si los prestamos o traslados tienen novedad los sacamos de los prestamos y traslados y solo quedan en novedades.
                const traslateGlobalesFincanoAsignadossinNews = traslateGlobalesFincanoAsignados.filter((item) => !news.find((item2) => item.IntCodigo === item2.IntCodigo_EMPL))
                //console.log({traslateGlobalesFincanoAsignadossinNews: traslateGlobalesFincanoAsignadossinNews})
                setViewTraslate(traslateGlobalesFincanoAsignadossinNews);
            } else {
                //console.log('No hay Traslados de la finca')                                             
            }


            //3. Saco de los empleados sinNews, los traslados tambien.
            sinNewsyTraslates = sinNews.filter((item) => !traslateGlobalesFincanoAsignados.find((item2) => item.IntCodigo === item2.IntCodigo));
            //console.log({sinNewsyTraslates: sinNewsyTraslates})

            //4. Pregunto si hay personal de otras fincas
            if (traslate.length > 0) {
                //console.log('Si hay Prestamos de Otras Fincas')
                //console.log({traslate: traslate})
                sinNewsyTraslatesyPOF = sinNewsyTraslates.filter((item) => !traslate.find((item2) => item.IntCodigo === item2.IntCodigo))
                //console.log({sinNewsyTraslatesyPOF: sinNewsyTraslatesyPOF})
            } else {
                //console.log('No hay Prestamos de Otras Fincas')
                //console.log({traslate: traslate})
                sinNewsyTraslatesyPOF = sinNewsyTraslates;
                //console.log({ sinNewsyTraslatesyPOF: sinNewsyTraslatesyPOF })
            }

            //5. Filtrar los trabajadores cuyo campo 'strCodigoCentro' contiene la palabra 'fertilizacion'
            const cuadrillaFer = sinNewsyTraslatesyPOF.filter(worker =>
                worker.strCodigoCentro && worker.strCodigoCentro.toLowerCase().includes('fertilizacion')
            );
            //console.log({cuadrillaFer: cuadrillaFer})
            setEmployeesCF(cuadrillaFer);

            //6. Saco el personal de fertilizacion de los no asignados
            if (cuadrillaFer.length > 0) {
                //console.log('hay personal de cuadrilla')
                sinNewsyTraslatesyPOFyCF = sinNewsyTraslatesyPOF.filter((item) => !cuadrillaFer.find((item2) => item.IntCodigo === item2.IntCodigo))
            } else {
                sinNewsyTraslatesyPOFyCF = sinNewsyTraslatesyPOF;
            }
            //console.log({sinNewsyTraslatesyPOFyCF: sinNewsyTraslatesyPOFyCF})

            //7. Pregunto si hay reparto
            if (distribution.length > 0) {
                //console.log('si hay distribución')                
                // Crear un array con todos los trabajadores asignados a los grupos
                const asigEmployees = Object.values(groups).reduce((acc, groupWorkers) => {
                    return acc.concat(groupWorkers);
                }, []); // El array inicial está vacío
                //console.log({ asigEmployees: asigEmployees })
                //Saco de los empleados que faltan por asignar las dsitribuciones de personal ya existentes
                sinNewsyTraslatesyPOFyDistribution = sinNewsyTraslatesyPOFyCF.filter((item) => !asigEmployees.find((item2) => item.IntCodigo === item2.IntCodigo));
                //console.log({ sinNewsyTraslatesyPOFyDistribution: sinNewsyTraslatesyPOFyDistribution })
                // Ordenar primero por 'centro' y luego por 'codigo'
                const sortedEmployees = [...sinNewsyTraslatesyPOFyDistribution].sort((a, b) => {
                    const centroComparison = a.strCodigoCentro.localeCompare(b.strCodigoCentro);
                    if (centroComparison === 0) {
                        return a.IntCodigo - b.IntCodigo; // Si los centros son iguales, ordenar por código
                    }
                    return centroComparison;
                });
                setEmployees(sortedEmployees);
                //setEmployees(sinNewsyTraslatesyPOFyDistribution);
                //Saco de los empleados que faltan por asignar de los prestamos y traslados de otras fincas las dsitribuciones de personal ya existentes
                const sinPOFyDistribution = traslate.filter((item) => !asigEmployees.find((item2) => item.IntCodigo === item2.IntCodigo));
                //console.log({sinPOFyDistribution: sinPOFyDistribution})
                setTraslate(sinPOFyDistribution);

            } else {
                //console.log('No hay distribución')                
                sinNewsyTraslatesyPOFyDistribution = sinNewsyTraslatesyPOFyCF;
                //console.log({ sinNewsyTraslatesyPOFyDistribution: sinNewsyTraslatesyPOFyDistribution })
                //console.log({sinNewsyTraslatesyPOFyCFennd: sinNewsyTraslatesyPOFyCF})

                // Ordenar primero por 'centro' y luego por 'codigo'
                const sortedEmployees = [...sinNewsyTraslatesyPOFyDistribution].sort((a, b) => {
                    const centroComparison = a.strCodigoCentro.localeCompare(b.strCodigoCentro);
                    if (centroComparison === 0) {
                        return a.IntCodigo - b.IntCodigo; // Si los centros son iguales, ordenar por código
                    }
                    return centroComparison;
                });
                setEmployees(sortedEmployees);
            }


        } else {
            //console.log('fecha es menor');          

            //5. Pregunto si hay reparto
            if (distribution.length > 0) {
                //console.log('si hay distribución')                                
                //setEmployees(sinNewsyTraslatesyPOFyDistribution);                
                //setTraslate(sinPOFyDistribution);
                //console.log({ditributionNews: distribution[0].workersNews}) 
                // Ordenar primero por 'centro' y luego por 'codigo'
                const sortedEmployees = [...distribution[0].workersnoAsignados].sort((a, b) => {
                    const centroComparison = a.strCodigoCentro.localeCompare(b.strCodigoCentro);
                    if (centroComparison === 0) {
                        return a.IntCodigo - b.IntCodigo; // Si los centros son iguales, ordenar por código
                    }
                    return centroComparison;
                });
                setEmployees(sortedEmployees);
                //setEmployees(distribution[0].workersnoAsignados); 
                setTraslate(distribution[0].workersnoAsignadosPOF);
                setNews(distribution[0].workersNews);
                setViewTraslate(distribution[0].workersTraslates);
                setEmployeesCF(distribution[0].workersCF);
            } else {
                //console.log('No hay distribución')               
            }

        }

    }
    //función para sacar las marcaciones de los empleados asignados
    const getEmployeesdialsData = async () => {

        //7. Pregunto si hay reparto
        if (distribution.length > 0) {
            // Crear un array con todos los trabajadores asignados a los grupos
            const asigEmployees = Object.values(groups).reduce((acc, groupWorkers) => {
                return acc.concat(groupWorkers);
            }, []); // El array inicial está vacío
            //Filtro los trabajadores asignados con marcacion            
            if (employeesdials.length > 0) {
                // Filtrar empleados que tienen marcaciones
                const withDials = employeesdials.filter((item) =>
                    asigEmployees.find((item2) => item.IntCodigo === item2.IntCodigo)
                );
                //console.log({ withDials: withDials })
                // Cargar los grupos
                const loadedGroups = {};
                // Iterar sobre las propiedades del objeto groups
                Object.entries(groups).forEach(([groupName, workers]) => {
                    // Agregar la marcación si coincide el código del trabajador con los códigos marcados
                    const workersWithMarcacion = workers.map((worker) => {
                        const tieneMarcacion = withDials.some(dial => dial.IntCodigo === worker.IntCodigo); //Encontrar con marcación
                        const tieneRdts = rdts.some(rdt => rdt.IntCodigo_EMPL === worker.IntCodigo); // Encontrar con Rdt
                        return {
                            ...worker,
                            marcacion: tieneMarcacion ? 'si' : 'no',
                            rdts: tieneRdts ? 'si' : 'no',
                            reparto: namerepart[groupName] //Utilizo el state que tiene todos los nombres de los repartos                           
                        };
                    });
                    loadedGroups[groupName] = workersWithMarcacion;
                });
                setGroups({ ...loadedGroups });
            }
        }

        //8. Pregunto si hay Rdts

    }
    //Para el manejo de arrastre de empleados a los grupos de labores en reparto 
    const onDragStart = (event, worker, fromGroup = null) => {
        // Convertimos el trabajador a un string JSON para transferirlo
        event.dataTransfer.setData('worker', JSON.stringify(worker));
        if (fromGroup) {
            event.dataTransfer.setData('fromGroup', fromGroup); // Solo si el trabajador viene de un grupo
        }
    };
    const onDrop = (event, toGroup) => {
        // Prevenir comportamiento por defecto
        event.preventDefault();

        try {
            // Intentamos obtener el trabajador del dataTransfer
            const worker = JSON.parse(event.dataTransfer.getData('worker'));
            const fromGroup = event.dataTransfer.getData('fromGroup'); // Grupo de origen, si existe

            if (!worker) {
                console.error('Error: No se encontró ningún trabajador en el drag.');
                return;
            }

            // Si el trabajador viene de un grupo, lo eliminamos del grupo original
            if (fromGroup) {
                setGroups(prevState => ({
                    ...prevState,
                    [fromGroup]: prevState[fromGroup].filter(w => w._id !== worker._id)
                }));
            } else {
                // Si viene de la lista de trabajadores, lo eliminamos de allí
                setEmployees(prevWorkers => prevWorkers.filter(w => w._id !== worker._id));
                //setTraslate(prevWorkers => prevWorkers.filter(w => w._id !== worker._id));
            }

            // Luego, agregamos el trabajador al grupo de destino
            setGroups(prevState => ({
                ...prevState,
                [toGroup]: Array.isArray(prevState[toGroup]) ? [...prevState[toGroup], worker] : [worker]
            }));

        } catch (error) {
            console.error('Error al procesar el trabajador: ', error);
        }
    };
    const allowDrop = (event) => {
        event.preventDefault(); // Permite que el trabajador sea soltado en esta área
    };
    //Función para liberar a un trabajador de un grupo específico
    const releaseWorker = (worker, fromGroup) => {
        setGroups(prevState => ({
            ...prevState,
            [fromGroup]: prevState[fromGroup].filter(w => w._id !== worker._id)
        }));

        setEmployees(prevWorkers => [...prevWorkers, worker]);
        //setTraslate(prevWorkers => [...prevWorkers, worker]);
    };
    //Función para liberar todos los trabajadores de un grupo específico
    const releaseAllFromGroup = (group) => {
        setEmployees(prevWorkers => [...prevWorkers, ...groups[group]]);
        //setTraslate(prevWorkers => [...prevWorkers, ...groups[group]]);

        setGroups(prevState => ({
            ...prevState,
            [group]: [] // Vaciamos el grupo
        }));
    };
    //Función para liberar a todos los trabajadores de todos los grupos
    const releaseAllFromAllGroups = () => {
        const allWorkersInGroups = [...groups.group1, ...groups.group2, ...groups.group3, ...groups.group4,
        ...groups.group5, ...groups.group6, ...groups.group7, ...groups.group8];

        setEmployees(prevWorkers => [...prevWorkers, ...allWorkersInGroups]);

        setGroups({
            group1: [], // Puyero C1
            group2: [], // colero C1
            group3: [], // garruchero C1
            group4: [], // Puyero C2
            group5: [], // colero C2
            group6: [], // garruchero C2
            group28: [], // Coordinadores Campo y Empaque
            group29: [], // Coordinador - Almacenista

        });
    };
    //Grabar Reparto
    const onSubmitsave = async () => {
        //Verifico que usurio sea de la misma finca para realizar reparto
        //console.log('Grabando...')
        //console.log({auth: auth})
        //console.log({sucursalSeleted: sucursalSeleted})
        if (auth.data.ibm != sucursalSeleted) {
            //console.log('No son la misma sucursal')
            showAlert(
                'No Autorizado',
                "Usted no esta autorizado para actualizar ó realizar reparto para esta finca",
                SW_ICON.INFO
            );
            return
        }
        //Valido Fecha
        const formatDate = (timestamp) => {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0 indexados
            const year = date.getFullYear();
            /*
            // Formatear hora            
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            console.log({hours: hours})
            */


            return `${year}-${month}-${day}`;
            //return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        };
        let currentDate = formatDate(Date.now());
        //console.log({currentDate: currentDate})        
        if (fechaini < currentDate) {
            //console.log('Usted ya no puede realizar reparto para fechas anteriores')
            showAlert(
                'No Permitido',
                "Usted ya no puede actualizar ó realizar reparto para fechas anteriores",
                SW_ICON.ERROR
            );
            return
        }
        //Valido hora
        const formatDatehour = (timestamp) => {
            const date = new Date(timestamp);
            //Formatear hora            
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            //console.log({hours: hours});         
            return `${hours}`;
        };
        let currentDateHour = formatDatehour(Date.now());

        //Hora para grabar debe ser menor a las 3 de la tarde.
        if (fechaini == currentDate && currentDateHour > 16) {
            showAlert(
                'No Permitido',
                "Señor usuario recuerde que solo se permite realizar reparto antes de las 3pm",
                SW_ICON.ERROR
            );
            return
        }
        //console.log({groups: groups})
        //Debemos tener al menos una persona administrativa
        if (groups.group29.length === 0 && groups.group28.length === 0) {
            //console.log('No hay asignado personal administrativo grupo 29 y 28 estan vacios')
            showAlert(
                'INFO',
                "Usted debe tener al menos una persona administrativa: 'Coordinador - Almacenista - Coordinadores Campo - Empacadora', para realizar el reparto.",
                SW_ICON.INFO
            );
            return
        }

        try {
            const fecha = Date.now();
            const dataRdt = {};
            dataRdt['strCodigo_SUCU'] = sucursalSeleted;
            dataRdt['dtmFecha_RDT'] = fechaini;

            dataRdt['email_USUA'] = auth.data.email;
            dataRdt['employeesId'] = auth.data._id;
            dataRdt['groups'] = groups;
            dataRdt['dtmFechaCrea'] = fecha;
            dataRdt['intCajasProgramadas'] = sumcajas;
            dataRdt['workersnoAsignados'] = employees;//No asiganados de la misma finca
            dataRdt['workersnoAsignadosPOF'] = traslate;//Prestamos no asiganados de otras fincas
            dataRdt['workersNews'] = news;//Prestamos no asiganados de otras fincas
            dataRdt['workersTraslates'] = viewTraslate;//Prestamos y traslados para otras fincas
            dataRdt['workersCF'] = employeesCF;//Personal de cuadrilla de fertilización contratado por la finca.
            dataRdt['strObservacion_RDT'] = observations;//Observations

            //console.log({data: data})
            //console.log({dataRdt: dataRdt})
            //console.log({groups: groups})
            //console.log({workersnoAsignados: employees})
            //console.log({workersnoAsignadosPOF: traslate})
            //console.log({workersNews: news})
            //console.log({workersTraslates: viewTraslate})
            //console.log({workersCF: employeesCF})        

            const response = await requestHttp(
                {
                    endpoint: '/personalDistributions',
                    body: dataRdt
                }
            );

            showAlert(
                'Grabado',
                "Correctamente",
                SW_ICON.SUCCESS
            );

        } catch (error) {
            console.log(error)

        }

    }
    //Ventana Modal para digitar los RDTs Employees
    const handleRdtsEmployees = (work, codLab) => {
        //Verificación labores en algunas fincas ya que se puede trabajar con otro cod.
        if (codLab == '91') { //Si cod labor es 91, entonces verificar si son fincas de pavarando.
            if (sucursalSeleted == '20175' || sucursalSeleted == '20180' || sucursalSeleted == '20186') { //Si son fincas de pavarando
                codLab = '136'; //Cambiar cod por el 136 que incluye dcyp.
            }
        } else if (codLab == '254') { //Si cod labor es 254, entonces verificar si son fincas de pavarando.
            if (sucursalSeleted == '20175' || sucursalSeleted == '20180' || sucursalSeleted == '20186') { //Si son fincas de pavarando
                codLab = '116'; //Cambiar cod por el 116 desmache para esta finca.
            } else if (sucursalSeleted == '80132') { //finca Agua verde
                codLab = '253'; //Cambiar cod por el 253 - DESMACHE X HECTAREAS para esta finca.
            }

        } else if (codLab == '143') { //Si cod labor es 143, entonces verificar si son fincas de pavarando.
            if (sucursalSeleted == '20175') { //Si son fincas de pavarando Asturias.
                codLab = '137'; //Cambiar cod por el 137 fumigacion contacto para esta finca.
            }
        }

        const rdtData = {
            ibm: sucursalSeleted,
            dateRdt: fechaini,
            codLab: codLab,
            codTrab: codLab == '9' ? work[0][0].IntCodigo : work.IntCodigo, //Si labor es embarque, tome codigo del puyero, sino; del trabajador reportado
            repart: codLab == '9' ? work[0][0].reparto : work.reparto,
            boardingStaff: codLab == '9' ? work : ''
        };

        setRdtprov(rdtData)

        setModalD(true)

        //console.log({handleGroup: work, codLab, sucursalSeleted})

        setTimeout(() => {
            setAnimarModalD(true)
        }, 300);
    }
    //Ventana Modal para visualizar los RDTs Reportados Employees campo
    const handleModalRdtscampo = () => {
        setModalDos(true)
        //setIdAplicate(idDetail);
        //console.log({ sucursalSeleted: sucursalSeleted })
        //console.log({ fechaini: fechaini })
        setTimeout(() => {
            setAnimarModalDos(true)
        }, 300);
    }
    //Ventana Modal para visualizar los RDTs Reportados Employees otros
    const handleModalRdtsotros = () => {
        setModalTres(true)
        //setIdAplicate(idDetail);
        //console.log({ sucursalSeleted: sucursalSeleted })
        //console.log({ fechaini: fechaini })
        setTimeout(() => {
            setAnimarModalTres(true)
        }, 300);
    }
    //Ventana Modal para visualizar los RDTs Reportados Employees corte
    const handleModalRdtscorte = () => {
        setModalCuatro(true)
        //setIdAplicate(idDetail);
        //console.log({ sucursalSeleted: sucursalSeleted })
        //console.log({ fechaini: fechaini })
        setTimeout(() => {
            setAnimarModalCuatro(true)
        }, 300);
    }
    //Ventana Modal para visualizar los RDTs Reportados Employees empaque
    const handleModalRdtsempaque = () => {
        setModalCinco(true)
        //setIdAplicate(idDetail);
        //console.log({ sucursalSeleted: sucursalSeleted })
        //console.log({ fechaini: fechaini })
        setTimeout(() => {
            setAnimarModalCinco(true)
        }, 300);
    }
    const { msg } = alert;

    return (
        <Page>
            <DistributionDetailTitle>
                REGISTRAR REPARTO DE LABORES
            </DistributionDetailTitle>
            {msg && <Alert alert={alert} />}
            <form>
                {/*ENCABEZADO*/}
                <PropertyTypesContainerRow>
                    <PropertyTypesContainerColum>
                        <LogoImageWrapper>
                            <img src={"Logodos.jpg"} alt="imgLogo" />
                        </LogoImageWrapper>
                    </PropertyTypesContainerColum>
                    <PropertyContainerInfo>
                        <PropertyTypesContainerColum>
                            <FormControl>
                                <FormControlInput>
                                    <label>FINCA REPORTADA</label>
                                    <select id="sucursal"
                                        {...register("sucursal", { required: true })}
                                        onChange={handleChange}
                                        value={sucursalSeleted}
                                        disabled={auth.data.role !== 1 && rdtprov.ibm !== 90021 ? true : false}>
                                        <option value={""}>--Seleccione--</option>
                                        {sucursal.map(item => (
                                            <option key={item.strCodigo}
                                                value={item.strCodigo}
                                                id={item.strCodigo}
                                            >{item.strNombre}</option>
                                        )
                                        )
                                        }
                                    </select>
                                </FormControlInput>
                            </FormControl>
                            <FormControl>
                                <FormControlInput>
                                    <label>FECHA REPARTO</label>
                                    <input type="date"
                                        {...register("fechaini")}
                                        onChange={handleChangeDate}
                                        value={fechaini}
                                    />
                                    {errors.fechaini?.type === 'required' && <span>Este Campo es requerido</span>}
                                </FormControlInput>
                            </FormControl>
                        </PropertyTypesContainerColum>

                        <PropertyTypesContainerColum>
                            <DistributionDetailInfo>
                                <p>
                                    USU:<span>{auth.data ? auth.data.email : 'Usuario no registrado'}</span><br />
                                    IBM:<span>{sucursalSeleted}</span><br />
                                    FEC:<span>{fechaini}</span><br />
                                    DIA:<span>"{nombreDia}"</span><br />
                                    TOTAL TRABAJADORES: <span>{totalemployees.length}</span>
                                </p>
                            </DistributionDetailInfo>
                        </PropertyTypesContainerColum>

                        <PropertyTypesContainerColum>
                            <PropertyWorkerslistGroup>
                                {/* Campo de Observaciones */}
                                <TextareaContainer>
                                    <label htmlFor="observations">Observaciones:</label>
                                    <ObservationsTextarea
                                        {...register("observations")}
                                        id="observations"
                                        value={observations}
                                        onChange={(e) => setObservations(e.target.value)}
                                        placeholder="Escribe tus observaciones aquí..."
                                    />
                                </TextareaContainer>
                            </PropertyWorkerslistGroup>
                        </PropertyTypesContainerColum>
                    </PropertyContainerInfo>
                    <PropertyTypesContainerColum>
                        <PropertyContainerInfoCajas>
                            <PropertyTypesContainerColum>
                                <FormControlInputSearch>
                                    <label>Cajas Programadas:</label>
                                    <input type="number"
                                        {...register("cajas")}
                                        value={sumcajas}
                                        onChange={e => setSumcajas(e.target.value)}
                                        tabIndex="14"
                                        id="input4"
                                    />
                                </FormControlInputSearch>
                            </PropertyTypesContainerColum>
                            <PropertyTypesContainerColum>
                                <Button label="GRABAR" onPress={onSubmitsave} />
                            </PropertyTypesContainerColum>

                        </PropertyContainerInfoCajas>
                    </PropertyTypesContainerColum>
                    <PropertyTypesContainerColum>
                        <PropertyItemInfo
                            info={'conmarcacion'}
                        >
                            Con Marcación
                        </PropertyItemInfo>
                        <PropertyItemInfo
                            info={'sinmarcacion'}
                        >
                            Sin Marcación
                        </PropertyItemInfo>
                        <PropertyItemInfo
                            info={'conrdt'}
                        >
                            Con RDT
                        </PropertyItemInfo>
                        <PropertyItemInfo
                            info={'sinrdt'}
                        >
                            Sin RDT
                        </PropertyItemInfo>
                    </PropertyTypesContainerColum>
                </PropertyTypesContainerRow>
            </form>
            <PropertyApp>
                {/*POR ASIGNAR y NOVEDADES*/}
                <PropertyWorkerslistGroup>
                    <h2>POR ASIGNAR: <span>{employees.length}</span></h2>
                    {employees.map(worker => (
                        <PropertyWorker
                            key={worker._id}
                            draggable
                            onDragStart={(event) => onDragStart(event, worker)}

                        >
                            {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                        </PropertyWorker>
                    ))}

                    <h2>PERSONAL DE OTRAS FINCAS: <span>{traslate.length}</span></h2>
                    {traslate.map(worker => (
                        <PropertyWorker
                            key={worker._id}
                            draggable
                            onDragStart={(event) => onDragStart(event, worker)}

                        >
                            {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro_EMPL + ' - ' + worker.strMotivo}
                        </PropertyWorker>
                    ))}

                    <h2>NOVEDADES: <span>{news.length}</span></h2>
                    {news.map(worker => (
                        <PropertyWorker
                            key={worker._id}
                            draggable
                            onDragStart={(event) => onDragStart(event, worker)}

                        >
                            {worker.IntCodigo_EMPL + ' ' + worker.strNombreTrabajador + ' - ' + worker.strConcepto}
                        </PropertyWorker>
                    ))}

                    <h2>CUADRILLA FERTILIZACIÓN: <span>{employeesCF.length}</span></h2>
                    {employeesCF.map(worker => (
                        <PropertyWorker
                            key={worker._id}
                            draggable
                            onDragStart={(event) => onDragStart(event, worker)}

                        >
                            {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                        </PropertyWorker>
                    ))}

                    <h2>PRESTADOS ó TRASLADADOS: <span>{viewTraslate.length}</span></h2>
                    {viewTraslate.map(worker => (
                        <PropertyWorker
                            key={worker._id}
                            draggable
                            onDragStart={(event) => onDragStart(event, worker)}

                        >
                            {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro + ' - ' + worker.strMotivo}
                        </PropertyWorker>
                    ))}

                </PropertyWorkerslistGroup>
                {/*ADMINISTRATIVOS y OTROS*/}
                <PropertyWorkerslistGroup>
                    <h2>ADMINISTRATIVOS: {(groups.group28 ? groups.group28.length : 0) + (groups.group29 ? groups.group29.length : 0)}</h2>
                    <PropertysubGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group29')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coordinador - Almacenista: <span>{groups.group29.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group29.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group29')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group29.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group29')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    <button onClick={() => releaseWorker(worker, 'group29')}>
                                        -
                                    </button>

                                </PropertyItem>
                            ))}

                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group28')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coordinadores Campo - Empacadora: <span>{groups.group28.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group28.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group28')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group28.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group28')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    <button onClick={() => releaseWorker(worker, 'group28')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                    </PropertysubGroup>
                    <h2>OTROS: {(groups.group33 ? groups.group33.length : 0) + (groups.group34 ? groups.group34.length : 0) + (groups.group35 ? groups.group35.length : 0) +
                        (groups.group36 ? groups.group36.length : 0) + (groups.group46 ? groups.group46.length : 0)}
                        <PropertyItemRdt>
                            <button onClick={() => handleModalRdtsotros()}>Ver rdts...</button>
                        </PropertyItemRdt>
                    </h2>

                    <PropertysubGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group33')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Labores Varias: <span>{groups.group33 ? groups.group33.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo */}
                                    {groups.group33 && groups.group33.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group33')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group33 && groups.group33.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group33')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    >
                                        <button onClick={() => handleRdtsEmployees(worker, '147', 'Varias')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group33')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group34')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Reubicados: <span>{groups.group34 ? groups.group34.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo */}
                                    {groups.group34 && groups.group34.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group34')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group34 && groups.group34.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group34')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '63')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group34')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group35')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Cablero: <span>{groups.group35 ? groups.group35.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo */}
                                    {groups.group35 && groups.group35.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group35')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group35 && groups.group35.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group35')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '159')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group35')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group36')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Repique: <span>{groups.group36 ? groups.group36.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo */}
                                    {groups.group36 && groups.group36.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group36')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group36 && groups.group36.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group36')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '223')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group36')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group46')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Operadores Retro: <span>{groups.group46 ? groups.group46.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo */}
                                    {groups.group46 && groups.group46.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group46')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group46 && groups.group46.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group46')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '198')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group46')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                    </PropertysubGroup>
                </PropertyWorkerslistGroup>
                {/*CAMPO*/}
                <PropertyWorkerslistGroup>
                    <h2>CAMPO: {groups.group21.length + groups.group22.length + groups.group23.length + groups.group24.length + groups.group25.length +
                        groups.group26.length + groups.group27.length + (groups.group47 ? groups.group47.length : 0)}
                        <PropertyItemRdt>
                            <button onClick={() => handleModalRdtscampo()}>Ver rdts...</button>
                        </PropertyItemRdt>
                    </h2>
                    <PropertysubGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group21')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>

                                <h3>Embolse: <span>{groups.group21.length}</span>

                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group21.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group21')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group21.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group21')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '118')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>

                                    <button onClick={() => releaseWorker(worker, 'group21')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group22')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Amarre: <span>{groups.group22.length}</span>

                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group22.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group22')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group22.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group22')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '113')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group22')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group23')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Desmache: <span>{groups.group23.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group23.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group23')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group23.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group23')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '254')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group23')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                    </PropertysubGroup>

                    <PropertysubGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group24')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Dcyp - Dyc: <span>{groups.group24.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group24.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group24')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group24.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group24')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '91')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group24')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group47')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Protección Fruta: <span>{groups.group47 ? groups.group47.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 47 */}
                                    {groups.group47 && groups.group47.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group47')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group47 && groups.group47.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group47')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '114')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group47')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group25')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Manejo de Cobertura: <span>{groups.group25.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group25.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group25')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group25.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group25')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '143')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group25')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group26')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Colocar Guantelete: <span>{groups.group26.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group26.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group26')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group26.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group26')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '119')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group26')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>

                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group27')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Resiembras: <span>{groups.group27.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group27.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group27')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group27.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group27')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '324')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group27')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                    </PropertysubGroup>
                </PropertyWorkerslistGroup>
                {/*CORTE*/}
                <PropertyWorkerslistGroup>
                    <h2>CORTE: {groups.group1.length + groups.group2.length + groups.group3.length + groups.group4.length + groups.group5.length + groups.group6.length +
                        groups.group7.length + groups.group8.length + groups.group9.length + groups.group10.length + groups.group11.length + groups.group12.length +
                        (groups.group37 ? groups.group37.length : 0) + (groups.group38 ? groups.group38.length : 0) + (groups.group39 ? groups.group39.length : 0) +
                        (groups.group40 ? groups.group40.length : 0) + (groups.group41 ? groups.group41.length : 0) + (groups.group42 ? groups.group42.length : 0) +
                        (groups.group43 ? groups.group43.length : 0) + (groups.group44 ? groups.group44.length : 0) + (groups.group45 ? groups.group45.length : 0)}
                        <PropertyItemRdt>
                            <button onClick={() => handleModalRdtscorte()}>Ver rdts...</button>
                        </PropertyItemRdt>
                    </h2>
                    {/* CUADRILLA 1*/}
                    <PropertysubGroup>
                        <h2>CUADRILLA #1: {groups.group1.length + groups.group2.length + groups.group3.length}</h2>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group1')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Puyero: <span>{groups.group1.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group1.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group1')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group1.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group1')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>

                                    <button onClick={() => releaseWorker(worker, 'group1')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group2')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coleros: <span>{groups.group2.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group2.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group2')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group2.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group2')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group2')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group3')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Garrucheros: <span>{groups.group3.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group3.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group3')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group3.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group3')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group3')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                    </PropertysubGroup>
                    {/* CUADRILLA 2*/}
                    <PropertysubGroup>
                        <h2>CUADRILLA #2: {groups.group4.length + groups.group5.length + groups.group6.length}</h2>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group4')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Puyero: <span>{groups.group4.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group4.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group4')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group4.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group4')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group4')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group5')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coleros: <span>{groups.group5.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group5.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group5')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group5.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group5')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group5')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group6')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Garrucheros: <span>{groups.group6.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group6.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group6')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group6.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group6')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group6')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                    </PropertysubGroup>
                    {/* CUADRILLA 3*/}
                    <PropertysubGroup>
                        <h2>CUADRILLA #3: {groups.group7.length + groups.group8.length + groups.group9.length}</h2>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group7')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Puyero: <span>{groups.group7.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group7.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group7')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group7.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group7')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group7')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group8')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coleros: <span>{groups.group8.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group8.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group8')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group8.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group8')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group8')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group9')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Garrucheros: <span>{groups.group9.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group9.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group9')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group9.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group9')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group9')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                    </PropertysubGroup>
                    {/* CUADRILLA 4*/}
                    <PropertysubGroup>
                        <h2>CUADRILLA #4: {groups.group10.length + groups.group11.length + groups.group12.length}</h2>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group10')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Puyero: <span>{groups.group10.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group10.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group10')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group10.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group10')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group10')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group11')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coleros: <span>{groups.group11.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group11.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group11')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group11.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group11')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group11')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group12')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Garrucheros: <span>{groups.group12.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group12.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group12')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group12.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group12')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group12')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                    </PropertysubGroup>
                    {/* CUADRILLA 5*/}
                    <PropertysubGroup>
                        <h2>CUADRILLA #5: {(groups.group37 ? groups.group37.length : 0) + (groups.group38 ? groups.group38.length : 0) + (groups.group39 ? groups.group39.length : 0)}</h2>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group37')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Puyero: <span>{groups.group37 ? groups.group37.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group37 && groups.group37.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group37')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group37 && groups.group37.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group37')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group37')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group38')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coleros: <span>{groups.group38 ? groups.group38.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group38 && groups.group38.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group38')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group38 && groups.group38.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group38')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group38')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group39')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Garrucheros: <span>{groups.group39 ? groups.group39.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group39 && groups.group39.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group39')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group39 && groups.group39.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group39')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group39')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                    </PropertysubGroup>
                    {/* CUADRILLA 6*/}
                    <PropertysubGroup>
                        <h2>CUADRILLA #6: {(groups.group40 ? groups.group40.length : 0) + (groups.group41 ? groups.group41.length : 0) + (groups.group42 ? groups.group42.length : 0)}</h2>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group40')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Puyero: <span>{groups.group40 ? groups.group40.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group40 && groups.group40.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group40')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group40 && groups.group40.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group40')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group40')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group41')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coleros: <span>{groups.group41 ? groups.group41.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group41 && groups.group41.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group41')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group41 && groups.group41.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group41')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group41')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group42')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Garrucheros: <span>{groups.group42 ? groups.group42.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group42 && groups.group42.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group42')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group42 && groups.group42.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group42')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group42')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                    </PropertysubGroup>
                    {/* CUADRILLA 7*/}
                    <PropertysubGroup>
                        <h2>CUADRILLA #7: {(groups.group43 ? groups.group43.length : 0) + (groups.group44 ? groups.group44.length : 0) + (groups.group45 ? groups.group45.length : 0)}</h2>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group43')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Puyero: <span>{groups.group43 ? groups.group43.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group43 && groups.group43.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group43')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group43 && groups.group43.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group43')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group43')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group44')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Coleros: <span>{groups.group44 ? groups.group44.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group44 && groups.group44.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group44')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group44 && groups.group44.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group44')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group44')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group45')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Garrucheros: <span>{groups.group45 ? groups.group45.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group45 && groups.group45.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group45')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group45 && groups.group45.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group45')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group45')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                    </PropertysubGroup>
                </PropertyWorkerslistGroup>
                {/*EMPAQUE*/}
                <PropertyWorkerslistGroup>
                    <h2>EMPACADORA: {groups.group13.length + groups.group14.length + groups.group15.length + groups.group16.length + groups.group17.length +
                        groups.group18.length + groups.group19.length + groups.group20.length + groups.group30.length + groups.group31.length +
                        groups.group32.length + (groups.group48 ? groups.group48.length : 0)}
                        <PropertyItemRdt>
                            <button onClick={() => handleModalRdtsempaque()}>Ver rdts...</button>
                        </PropertyItemRdt>
                    </h2>
                    <PropertysubGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group13')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Barcadilla: <span>{groups.group13.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group13.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group13')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group13.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group13')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '175')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group13')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group14')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Guantelete: <span>{groups.group14.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group14.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group14')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group14.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group14')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '77')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group14')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group15')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Cochinilla: <span>{groups.group15.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group15.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group15')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group15.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group15')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '106')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group15')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group48')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Bolsa Nacional: <span>{groups.group48 ? groups.group48.length : 0}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group48 && groups.group48.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group48')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group48 && groups.group48.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group48')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '3')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group48')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group19')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Cartonera: <span>{groups.group19.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group19.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group19')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group19.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group19')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees(worker, '394')}>+</button>{worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}</PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group19')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                    </PropertysubGroup>

                    <PropertysubGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group16')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Desmane: <span>{groups.group16.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 1 */}
                                    {groups.group16.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group16')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group16.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group16')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group16')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group17')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Selección: <span>{groups.group17.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 2 */}
                                    {groups.group17.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group17')}>
                                            -
                                        </button>
                                    )}
                                </h3>
                            </PropertyItemButton>
                            {groups.group17.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group17')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group17')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />
                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group30')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Clasificación: <span>{groups.group30.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group30.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group30')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group30.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group30')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group30')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group18')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Sello y Peso: <span>{groups.group18.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group18.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group18')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group18.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group18')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group18')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group32')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Surtidor: <span>{groups.group32.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group32.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group32')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group32.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group32')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group32')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group31')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Empaque: <span>{groups.group31.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group31.length > 0 && (
                                        <button onClick={() => releaseAllFromGroup('group31')}>
                                            -
                                        </button>
                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group31.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group31')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group31')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>

                        <PropertyGroup
                            onDrop={(event) => onDrop(event, 'group20')}
                            onDragOver={allowDrop}
                        >
                            <PropertyItemButton>
                                <h3>Palletizado: <span>{groups.group20.length}</span>
                                    {/* Botón para liberar a todos los trabajadores del Grupo 3 */}
                                    {groups.group20.length > 0 && (

                                        <button onClick={() => releaseAllFromGroup('group20')}
                                            tooltip='Eliminar'
                                        >
                                            -
                                        </button>

                                    )}</h3>
                            </PropertyItemButton>
                            {groups.group20.map(worker => (
                                <PropertyItem
                                    key={worker._id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, worker, 'group20')}
                                    centro={worker.strCodigoCentro}
                                    marcacion={worker.marcacion}
                                >
                                    <PropertyItemRdt
                                        rdts={worker.rdts}
                                    ><button onClick={() => handleRdtsEmployees((
                                        [groups.group1, groups.group2, groups.group3, groups.group4, groups.group5, groups.group6,
                                        groups.group7, groups.group8, groups.group9, groups.group10, groups.group11, groups.group12,
                                        groups.group37, groups.group38, groups.group39, groups.group40, groups.group41, groups.group42,
                                        groups.group43, groups.group44, groups.group45,
                                        groups.group16, groups.group17, groups.group18, groups.group20, groups.group30, groups.group31,
                                        groups.group32]), '9')}>+</button>
                                        {worker.IntCodigo + ' ' + worker.strNombreTrabajador + ' - ' + worker.strCodigoCentro}
                                    </PropertyItemRdt>
                                    <button onClick={() => releaseWorker(worker, 'group20')}>
                                        -
                                    </button>
                                </PropertyItem>
                            ))}
                            <br />

                        </PropertyGroup>
                    </PropertysubGroup>
                </PropertyWorkerslistGroup>
            </PropertyApp>
            {modald && <AddRdtsDistribution
                setModalD={setModalD}
                animarModald={animarModald}
            />
            }
            {modaldos && <ModalRdtscampo
                setModalDos={setModalDos}
                animarModaldos={animarModaldos}
                sucursalSeleted={sucursalSeleted}
                fechaini={fechaini}
                groups={groups}
            />
            }
            {modaltres && <ModalRdtsotros
                setModalTres={setModalTres}
                animarModaltres={animarModaltres}
                sucursalSeleted={sucursalSeleted}
                fechaini={fechaini}
                groups={groups}
            />
            }
            {modalcuatro && <ModalRdtscorte
                setModalCuatro={setModalCuatro}
                animarModalcuatro={animarModalcuatro}
                sucursalSeleted={sucursalSeleted}
                fechaini={fechaini}
                groups={groups}
            />
            }
            {modalcinco && <ModalRdtsempaque
                setModalCinco={setModalCinco}
                animarModalcinco={animarModalcinco}
                sucursalSeleted={sucursalSeleted}
                fechaini={fechaini}
                groups={groups}
            />
            }
        </Page>
    )
}