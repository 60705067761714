import { IoHome, IoPerson, IoLocation, IoStar, IoAddCircle, IoPodium } from 'react-icons/io5'
import { MenuItem } from './MenuItem';
import { MenuWrapper } from './styles';
import { useAuth } from "../../hooks/useAuth";

const MenuItems = [
    {
        key: 1,
        icon: IoHome,
        label: 'Inicio',
        path: '/'
    },
    {
        key: 4,
        icon: IoPerson,
        label: 'Perfil',
        path: '/account'
    }
];
const MenuCustomerItems = [
    {
        key: 1,
        icon: IoHome,
        label: 'Inicio',
        path: '/'
    },
    {
        key: 2,
        icon: IoAddCircle,
        label: 'Novedades',
        path: '/News'
    },
    {
        key: 4,
        icon: IoPerson,
        label: 'Perfil',
        path: '/account'
    }
];

const MenuAdminItems = [
    {
        key: 1,
        icon: IoHome,
        label: 'Inicio',
        path: '/'
    },
    {
        key: 2,
        icon: IoAddCircle,
        label: 'Novedades',
        path: '/News'
    },
    /*{
        key: 3,
        icon: IoAddCircle,
        label: 'Sincronizar',
        path: '/Sincronizations'
    },*/
    {
        key: 4,
        icon: IoPerson,
        label: 'Perfil',
        path: '/account'
    }
];

export const Menu = () => {
    const { auth } = useAuth();
    //console.log(auth.data)  
    const typeProfile = (auth.data ? auth.data.role : 0);
    //console.log(typeProfile)
    return (
        <div>
            <MenuWrapper>
                {
                    typeProfile === 0 && MenuItems.map(item => <MenuItem{...item} />)

                }
                {
                    typeProfile === 1 && MenuAdminItems.map(item => <MenuItem{...item} />)
                }
                {
                    typeProfile === 2 && MenuCustomerItems.map(item => <MenuItem{...item} />)

                }
                {
                    typeProfile === 3 && MenuCustomerItems.map(item => <MenuItem{...item} />)

                }
            </MenuWrapper>
        </div>
    )
};