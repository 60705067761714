import styled, { css } from "styled-components";

export const PropertyTypesContainerHead = styled.section`
    
    //border: 1px solid red;
    display: flex;  
    margin: 10px 0 ;    
    
`;
export const PropertyTypesContainerColum = styled.section`

    //border: 1px solid yellow;
    //padding-right: 20px;  

    
    //width: 100%; // Por defecto, ocupa todo el ancho disponible
    //padding: 5px;
    //font-size: 16px;
    //border: 1px solid #ccc;
    //border-radius: 5px;
    box-sizing: border-box; // Asegura que padding no desborde el contenedor          

    img {      
        // Para móviles (ocultar imagen en pantallas menores o iguales a 512px)
        @media (max-width: 512px) {
            display: none;
        }
    } 
    
`;
export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    display: flex;    
    gap: 10px; // Espaciado entre elementos    
    //align-items: center; // Alinear verticalmente
    //overflow: hidden; // Evitar desbordamientos visibles
    
    //Para moviles
    @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
        margin-top: 20px;
        padding:0 5px;            
        justify-content: center;
        width: 100%;        
    }

    // Ajuste para tablets o pantallas medianas
    @media (min-width: 513px) and (max-width: 768px) {
        //border: 1px solid red;
        margin-top: 20px;
        padding:0 5px;            
        //justify-content: center;
        width: 100%;
        flex-wrap: wrap; // Permitir que los elementos se envuelvan
        img{
            display:none;
        }
               

    }

    // Ajuste para tablets o pantallas medianas horizontal
    @media (min-width: 769px) and (max-width: 1180px) {        
        //border: 3px solid red;
        flex-wrap: wrap; // Permitir que los elementos se envuelvan
        //justify-content: space-between; // Distribuir elementos de manera uniforme

        img{
            display:none;
        }

    }

`;
//Pruebas
export const PropertyTypesContainerRowGroupG = styled.section`
    //border: 1px solid red;
    display: flex; 
    flex-wrap: wrap; // Permitir que los elementos se acomoden si es necesario
    gap: 10px; // Espacio entre los elementos
    margin: 5px;
    padding: 20px;
    width: 100%; // Ocupa todo el ancho disponible
    justify-content: space-around; // Distribuir espacio entre elementos

    // Estilo de los elementos hijos (los 5 objetos)
    > * {
        flex: 1 1 calc(20% - 10px); // Cinco elementos en una fila en pantallas grandes
        max-width: calc(20% - 10px); // Cada uno ocupa 20% del ancho menos el gap
        box-sizing: border-box;
    }

    // Pantallas medianas (3 columnas)
    @media (min-width: 821px) and (max-width: 1181px) {
       
        > * {
            flex: 1 1 calc(33.33% - 10px); // Tres columnas
            max-width: calc(33.33% - 10px);
        }
    }

    // Pantallas pequeñas (2 columnas)
    @media (max-width: 820px) {
        > * {
            flex: 1 1 calc(50% - 10px); // Dos columnas
            max-width: calc(50% - 10px);
        }
    }

    // Móviles (1 columna)
    @media (max-width: 480px) {
        
        > * {
            flex: 1 1 100%; // Una columna en móviles
            max-width: 100%;
        }
    }
`;
export const PropertyTypesContainer = styled.section`
    //border: 1px solid orange;
    display: flex;
    overflow-x: scroll; 
    
`;
export const PropertyInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin: 0 10px 0 10px;
    text-align: left;
    color:#6262A0;

    h4{
        color: #26794f;
        span{
            color: black;
        }
    }
    
        
`;
export const DistributionDetailTitle = styled.h1`
    font-size: 1.5em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`;
export const DistributionDetailInfo = styled.div`    
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;    
    //border: 1px solid blue;
    width: 100%;

    span{
        font-size: 1.5em;
        font-weight: bold;        
    }
    p{
        text-align: left; 
        span{
            font-size: 1.1em;
            font-weight: bold;
        }
    }

    // Ajuste para tablets o pantallas medianas
    @media (min-width: 513px) and (max-width: 768px) {
        //border: 1px solid blue;        
        p{        
            span{
                font-size: 0.9em;                
            }
        }           
    }
`;
export const PropertyApp = styled.div`
    //border: 1px solid red;
    display: flex; 
    flex-wrap: wrap; // Permitir que los elementos se acomoden si es necesario
    gap: 10px; // Espacio entre los elementos
    margin: 5px;
    padding: 20px;
    width: 100%; // Ocupa todo el ancho disponible
    justify-content: space-around; // Distribuir espacio entre elementos

    // Estilo de los elementos hijos (los 5 objetos)
    > * {
        flex: 1 1 calc(20% - 10px); // Cinco elementos en una fila en pantallas grandes
        max-width: calc(20% - 10px); // Cada uno ocupa 20% del ancho menos el gap
        box-sizing: border-box;
    }

    // Pantallas medianas (3 columnas)
    @media (min-width: 981px) and (max-width: 1180px) {
       
        > * {
            flex: 1 1 calc(33.33% - 10px); // Tres columnas
            max-width: calc(33.33% - 10px);
        }
    }

    // Pantallas pequeñas (2 columnas)
    @media (max-width: 980px) {
        > * {
            flex: 1 1 calc(50% - 10px); // Dos columnas
            max-width: calc(50% - 10px);
        }
    }

    // Móviles (1 columna)
    @media (max-width: 512px) {
        
        > * {
            flex: 1 1 100%; // Una columna en móviles
            max-width: 100%;
        }
    }
`;
export const PropertyWorkerslistGroup = styled.div`
    //border: 1px solid red;
    font-size: 0.6em;
    width: 400px;
    max-height: 600px;
    overflow-y: auto;
    border: 2px solid #ccc;
    padding: 5px;
    border-radius: 8px;

    //Para moviles
    @media (max-width: 512px){
        font-size: 0.6em;
        width: auto;
        max-height: auto;
        overflow-y: auto;
        border: 2px solid #ccc;
        padding: 5px;
        border-radius: 8px;
        
    }

    
    h2{
        text-align: center;
    }
    button{           
        background-color: #f0a808;
        border: 1px solid #f0a808;
        margin-left: 10px;
        font-size: 1em;
    }
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;    
        button{
            cursor: pointer;                    
        }
    }
`;
export const PropertyWorker = styled.div`
    margin: 5px 0;
    padding: 5px;
    background-color: #f4f4f4;
    border-radius: 4px;
    text-align: center;

    &:hover{        
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff};      
    }
    
`;
export const PropertyGroup = styled.div`
    //border: 1px solid red;
    background-color: #f9f9f9;
`;
export const PropertysubGroup = styled.div`
    border: 2px solid #ccc;
    padding: 5px;

    h2{
        text-align:center;
    }
`;
export const PropertyItem = styled.div`
    //border: 1px solid red;  
    
    display: flex;
    justify-content: space-between;  
    align-items: left;
    font-size: 0.9em;
    padding-left: 5px;
    color: ${(props) => (props.centro && props.centro.toLowerCase().includes('prestamos') ? 'blue' : 'black')};
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: red;                  
        }
    }
    button{           
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#f0a808')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '1px solid #f0a808')};
        margin: 0.5px;
        font-size: 1em;        
    }    
`;
export const PropertyItemRdt = styled.div`

    //border: 1px solid red;  
    display: flex;
    justify-content: space-between;  
    align-items: center;
    //text-align: center;
    font-size: 0.9em;
    margin-right: 5px;
    color: ${(props) => (props.centro && props.centro.toLowerCase().includes('prestamos') ? 'blue' : 'black')};
    background-color: ${(props) => (props.info && props.info.toLowerCase().includes('conmarcacion') ? 'green' : '')};
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: blue;                  
        }
    }
    button{    
        color: white;       
        background-color: ${(props) => (props.rdts && props.rdts.toLowerCase().includes('si') ? '#9dd04d' : '#808080')};
        border: ${(props) => (props.rdts && props.rdts.toLowerCase().includes('si') ? '1px solid #9dd04d' : '1px solid #808080')};
        margin-right: 3px;
        font-size: 1em;   
        //border: 1px solid red;
       
    }
`;
export const PropertyItemInfo = styled.div`

    //border: 1px solid red;  
    display: flex;
    justify-content: space-between;  
    align-items: center;
    text-align: center;
    margin: 5px;
    font-size: 0.9em;
    //font-weight: bold;
    border-radius: 5px;
    margin-right: 15px;
    padding: 4px;    
    color: white;
    background-color: ${(props) => (props.info && props.info.toLowerCase().includes('conmarcacion') ? 'green' : '')};
    background-color: ${(props) => (props.info && props.info.toLowerCase().includes('sinmarcacion') ? '#f0a808' : '')};
    background-color: ${(props) => (props.info && props.info.toLowerCase().includes('conrdt') ? '#9dd04d' : '')};
    background-color: ${(props) => (props.info && props.info.toLowerCase().includes('sinrdt') ? '#808080' : '')};
    
`;
export const PropertyItemButton = styled.div`
    //border: 1px solid red;

    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        //background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: red;                  
        }
    }
        
    button{         
        padding-left: 5px;          
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#808080')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '4px solid #f9f9f9')};
        color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'blue' : 'white')};
        margin: 0.5px;
        font-size: 1em;   
        
        .tooltip {
            visibility: hidden;
            background-color: black;
            color: white;
            text-align: center;
            padding: 5px;
            border-radius: 5px;
            position: absolute;
            bottom: 125%; /* Posiciona el tooltip arriba del botón */
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            z-index: 1;
        }
    }

`;
export const FormControlInputSearch = styled.div`
    //border: 1px solid red;
    display: flex;   
    //margin: 0 10px 0 10px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;

    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
        margin-left: 10px;
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        //display: block;
        width: 30%;
        //border-radius: 10px;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 5px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }

    //Para Tablet o pantallas medianas
    @media (min-width: 513px) and (max-width: 700px) {
        border: 1px solid blue;
        margin: 0 2px 0 2px;
        flex-direction: column; 

        input{
            border: 1px solid blue;
            //width:auto;
        }

    }

    // Ajuste para tablets o pantallas medianas horizontal
    @media (min-width: 701px) and (max-width: 1180px) {        
        //border: 3px solid blue; 
        //padding: 0px;
        flex-direction: column;
        input[type="email"], input[type="password"],
        input[type="text"], input[type="number"],
        input[type="float"],input[type="date"]{
            //display: block;
            //border: 3px solid blue;
            width: 100%;
            //border-radius: 10px;
            font-size: 1em;
            padding: 5px 10px;            

        }  
        label{
            //border: 3px solid blue;
            padding: 0px; 
            margin:0px;
            font-size: 1em;           
        }       

    }
`;
export const TextareaContainer = styled.div`
  margin-top: 5px;
  //padding: 10px;
  //border: 1px solid red;
  //width:100%;
`;
export const ObservationsTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  box-sizing: border-box;
`;
export const PropertyContainerInfo = styled.div`
    //border: 1px solid red;
    //background-color: #f9f9f9;
    // Ajuste para tablets o pantallas medianas
    
    @media (min-width: 513px) {
        //border: 1px solid red;
        //flex-direction: row; 
        //flex-wrap: wrap;       
        display: flex;
    }
`;
export const PropertyContainerInfoCajas = styled.div`
    //border: 1px solid blue;
    //background-color: #f9f9f9;
    // Ajuste para tablets o pantallas medianas
    
    @media (min-width: 513px) and (max-width: 1024px) {   
        //border: 1px solid red;
        //flex-wrap: wrap;       
        display: flex;
        gap: 10px; // Espaciado entre elementos    
        align-items: center; // Alinear verticalmente
        overflow: hidden; // Evitar desbordamientos visibles
        margin-top: 0px;
        width:100%;
        button{
            width: 150px;
        }
         
    }
`;
const BaseButtonStyle = css`
    width: 100%;
    font-size: 1em;
    color: blue;
    //text-decoration-line: underline;
    font-weight: bold;
    //background-color: #4A148C;
    background-color: transparent;//verde osc
    //background-color: #ff8c00;//Naranja
    text-align: right;
    border: none;
    //border-radius: 6px;
    //padding: 5px 5px;
    //opacity: 0.9;
    cursor: pointer;    
    
    &hover{        
        opacity: 1;
    }   
`;
export const ButtonAplicate = styled.button`
    border: 1px solid red;
    ${BaseButtonStyle}
    
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }    
`;